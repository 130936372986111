/**
 * @copyright 2019 @ DigiNet
 * @author Minh Trung
 * @create 07/01/2020
 * @Example
 */

import React from "react"
import {bindActionCreators, compose} from "redux";
import {withStyles} from "@material-ui/core";
import {connect} from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import Config from "../../../../config";
import ButtonGeneral from "../../../common/button/button-general";
import Modal from "../../../common/modal/modal";
import GridContainer from "../../../grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";
import IconButton from "@material-ui/core/IconButton";
import Divider from "@material-ui/core/Divider";
import {Image,FormGroup,Row,Col} from "react-bootstrap";
import Filter from "../../../filter/filter";
import {Combo} from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import * as W75F2010Actions from "../../../../redux/W75/W75F2010/W75F2010_actions"
import moment from "moment";
import {browserHistory} from "react-router";
import W75F2010Popup from './W75F2010Popup'
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import Status from "../../../common/status/status";
import Icons from "../../../common/icons/";

const styles =  {
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        overflow: 'hidden',
        marginRight: 8,
    },
    imgAvatar: {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
    },
    dateInfo: {
        display: 'flex',
        alignItems: 'center',
    },
    divText: {
        // fontSize: "14px",
        fontWeight: 500,
        // color: '#575757',
    },
    divDateText: {
        fontSize: "14px",
        fontWeight: 400,
        color: '#000000',
    }
};

class W75F2010 extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            mode: "add",
            rowData: null,
            showW75F2010Popup: false,
            dataCboEmployees: {
                rows: [],
                total: 0
            },
            dataCboProjects: {
                rows: [],
                total: 0
            },
            loading: {
                cboEmployees: false,
                cboProjects: false,
            },
        };
        this.loading = {
            cboEmployees: false,
            cboProjects: false,
        };
        this.filter = {
            PeriodTime : "",
            DateFrom:null,
            DateTo:null,
            Project: null,
            AppStatusID:"",
            VoucherDate:"",
            Employee: null,
            EmployeeID: "",
            skip: 0,
            limit: 10,
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50
        };
        this.filterCboProject = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50
        };
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W75F2010", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    onSearch = ()=> {
        this.loadDataGrid();
    };

    onChangePageProfile = (data) => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: { EmployeeID: data.EmployeeID}
        });
    };

    renderEmpProfile = (e)=>{
        const {classes} = this.props;
        const {data} = e.row;
        const date = Config.convertDate(data.EntryDate, "", "LT, DD/MM/YYYY", true);

        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage data={data} onClick={() => this.onChangePageProfile(data)}/>
                <div className={"w75f2000_info"}>
                    <div className={classes.divText}><UserName data={data}/></div>
                    <div className={classes.dateInfo}>
                        <Image src={require('../../../../assets/images/icon-calendar.svg')}/>
                        <div style={{paddingLeft:'8px'}} className={"date-text-info"}>{data.EntryDate && date }</div>
                    </div>
                </div>
            </div>
        )
    };

    renderInfo = (e) =>{
        const {data} = e.row;
        const date = moment(data.ApprovalDate).format("DD/MM/YYYY");
        const {classes} = this.props;
        return (
            <div className={"pdt10 pdb10 " + classes.divText}>
                <div>{Config.lang("DHR_Ky_luong")}: {data.Period}</div>
                <div>{data.Note}</div>
                <div>{Config.lang("DHR_Nguoi_lap")}: {data.Proposer}</div>
                {
                    data.ApproverName &&
                    <div>
                        {Config.lang("DHR_Nguoi_duyet")}: {data.ApproverName} - {data.DepartmentID} - {date}
                    </div>
                }
               {data.AppStatusID > 0 && <div>{Config.lang("DHR_Ghi_chu_duyet")}: {data.ApprovalNotes}</div>}
            </div>
        )
    };

    renderStatus = (e) => {
        const {data} = e.row;
        return (
            <Status data={data} />
        );
    };

    onAdd = () => {
        this.setState({
            mode: "add",
            showW75F2010Popup: true
        });
    };

    onView = (e) => {
        const {data} = e.row;
        this.setState({
            mode: "view",
            rowData: data,
            showW75F2010Popup: true
        });
    };

    onEdit = ({data}) => {
        this.setState({
            mode: "edit",
            rowData: data,
            showW75F2010Popup: true
        });
    };

    onDelete = (data)=>{
        const {AbsentVoucherID,EmployeeID} = data;

        const param = {
            FormID:"W75F2010",
            EmployeeID:EmployeeID,
            TransID:AbsentVoucherID,
        };

        Config.popup.show('YES_NO',Config.lang("DHR_Ban_co_chac_muon_xoa?"),
            ()=>{
                this.props.W75F2010Actions.deleteDataGrid(param, (error) => {
                    if (error) {
                        Config.popup.show('INFO', Config.lang("DHR_Loi_chua_xac_dinh"));
                        return false;
                    }
                    else{
                        Config.notify.show("success", Config.lang("DHR_Xoa_thanh_cong"), 3000);
                        this.loadDataGrid();
                    }
                });
            });

    };

    renderAction =(e)=>{
        const {data} = e.row;

        return(
            <div className={"display_row align-center"}>
                <IconButton aria-label="view" size="small" onClick={() => this.onView(e)}>
                    <Icons name={"view"}/>
                </IconButton>
                <Divider orientation="vertical" style={{width: 2, height: 11, margin: '0 5px'}}/>
                <IconButton disabled={(data && Number(data.AppStatusID) !== 0)} aria-label="delete" size="small" onClick={()=>this.onDelete(data)}>
                    <Icons name={"delete"}/>
                </IconButton>
            </div>
        )
    };

    filterChange = (key,data) => {
        const {value}= data;
        if(!key) return false;
        switch (key) {
            case "PeriodTime":
                this.filter.PeriodTime = value;
                break;
            case "DateFrom":
                this.filter.DateFrom = value;
                break;
            case "DateTo":
                this.filter.DateTo = value;
                break;
            case "EmployeeID":
                this.filter.Employee = value;
                break;
            case "AbsentType":
                this.filter.VoucherDate = value;
                break;
            case "ProjectID":
                this.filter.Project = value;
                break;
            case "AppStatusID":
                this.filter.AppStatusID = value;
                break;
            default:
                break;
        }

    };

    renderFilter = ()=>{
        const {getCboPeriod,getCboAppStatus,classes} = this.props;
        const {PeriodTime, DateTo,DateFrom,Project, AppStatusID, Employee,} = this.filter;
        const {dataCboEmployees, dataCboProjects, loading} = this.state;

        return (
            <Filter
                placeholder={Config.lang("DHR_Nhap_tu_tim_kiem")}
                openOnFieldClick={true}
                renderFilter={
                    ()=>{
                        return (
                            <React.Fragment>
                                <FormGroup style={{marginBottom:"7px"}}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Combo
                                                label={Config.lang("DHR_Ky_luong")}
                                                dataSource={getCboPeriod}
                                                displayExpr={"PeriodTime"}
                                                valueExpr={"PeriodTime"}
                                                onValueChanged={(e)=>this.filterChange("PeriodTime",e)}
                                                showClearButton={true}
                                                value={PeriodTime}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup style={{marginBottom:"7px"}}>
                                    <Row>
                                        <Col xs={12} sm={6} md={6} lg={6}>
                                            <div style={{display:"flex",alignItems: "center"}}>
                                                    <div className={classes.divText}>{Config.lang("DHR_Tu")}</div>
                                                <DateBoxPicker
                                                    dateBoxProps = {{max: DateTo}}
                                                    style={{marginLeft:"5px"}}
                                                    onValueChanged={(e)=>this.filterChange("DateFrom",e)}
                                                    useMaskBehavior={true}
                                                    showClearButton={true}
                                                    placeholder={"DD/MM/YYYY"}
                                                    value={DateFrom}
                                                />
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={6} md={6} lg={6}>
                                            <div style={{display:"flex",alignItems: "center"}}>
                                                <div className={classes.divText}>{Config.lang("DHR_Den").toLowerCase()}</div>
                                                <DateBoxPicker
                                                    dateBoxProps = {{min: DateFrom}}
                                                    style={{marginLeft:"5px"}}
                                                    onValueChanged={(e)=>this.filterChange("DateTo",e)}
                                                    useMaskBehavior={true}
                                                    showClearButton={true}
                                                    // max={moment()}
                                                    // min={DateFrom && DateFrom}
                                                    placeholder={"DD/MM/YYYY"}
                                                    value={DateTo && DateTo}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup style={{marginBottom:"7px"}}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Combo
                                                label={Config.lang("DHR_Trang_thai_duyet")}
                                                dataSource={getCboAppStatus}
                                                displayExpr={"AppStatusName"}
                                                valueExpr={"ApprovalStatus"}
                                                onValueChanged={(e)=>this.filterChange("AppStatusID",e)}
                                                showClearButton={true}
                                                value={AppStatusID}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup style={{marginBottom:"7px"}}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Combo.LoadMore
                                                dataSource={dataCboEmployees}
                                                skip={this.filterCboEmployees.skip}
                                                limit={this.filterCboEmployees.limit}
                                                displayExpr={"EmployeeName"}
                                                keyExpr={'EmployeeID'}
                                                valueExpr={'EmployeeID'}
                                                value={Employee}
                                                stylingMode={'outlined'}
                                                loading={loading.cboEmployees}
                                                label={Config.lang('DHR_Nhan_vien')}
                                                itemRender={(e) => {
                                                    const {data} = e;
                                                    if (!data) return null;
                                                    return data.EmployeeID + ' - ' + data.EmployeeName;
                                                }}
                                                showClearButton={true}
                                                // showCheckBoxMode={"none"}
                                                onValueChanged={e => this.filterChange("EmployeeID", e)}
                                                onInputChanged={(e) => {
                                                    this.filterCboEmployees.strSearch = e.target.value;
                                                    this.filterCboEmployees.skip = 0;
                                                    this.loadCboEmployees(true);
                                                }}
                                                onLoadMore={(e) => {
                                                    this.filterCboEmployees.skip = e.skip;
                                                    this.filterCboEmployees.limit = e.limit;
                                                    this.loadCboEmployees();
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <FormGroup style={{marginBottom:"7px"}}>
                                    <Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Combo.LoadMore
                                                dataSource={dataCboProjects}
                                                skip={this.filterCboProject.skip}
                                                limit={this.filterCboProject.limit}
                                                displayExpr={'ProjectName'}
                                                keyExpr={'ProjectID'}
                                                valueExpr={'ProjectID'}
                                                value={Project}
                                                loading={loading.cboProjects}
                                                stylingMode={'outlined'}
                                                label={Config.lang('DHR_Du_an')}
                                                itemRender={(e) => {
                                                    const {data} = e;
                                                    if (!data) return null;
                                                    return data.ProjectID + ' - ' + data.ProjectName;
                                                }}
                                                showClearButton={true}
                                                // showCheckBoxMode={"none"}
                                                onValueChanged={e => this.filterChange("ProjectID", e)}
                                                onInputChanged={(e) => {
                                                    this.filterCboProject.strSearch = e.target.value;
                                                    this.filterCboProject.skip = 0;
                                                    this.loadCboProjects(true);
                                                }}
                                                onLoadMore={(e) => {
                                                    this.filterCboProject.skip = e.skip;
                                                    this.filterCboProject.limit = e.limit;
                                                    this.loadCboProjects();
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </FormGroup>
                                <div style={{display:'flex',justifyContent:"center",paddingTop:"38px",paddingBottom:"40px"}}>
                                    <ButtonGeneral
                                        name={Config.lang("DHR_Tim_kiem")}
                                        typeButton={"search"}
                                        size={"large"}
                                        color={"primary"}
                                        variant={"outlined"}
                                        onClick={this.onSearch}
                                    />
                                </div>
                            </React.Fragment>
                        )
                    }
                }
            />
        )
    };

    componentDidMount = async() => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
        this.loadCboEmployees();
        this.loadCboProjects();
        this.loadDataGrid();
        this.loadCboPeriod();
        this.loadCboAppStatus();
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
        const {getDataGrid} = this.props;
        Config.callChildForm({
            FormID: "W75F2010",
            // ID: voucher_id,
            keyExpr: "AbsentVoucherID",
            data: getDataGrid && getDataGrid.rows ? getDataGrid.rows : [],
            onLoad: (params) => this.loadDataGrid(params && params.ID),
            onAction: (e, data) => this.onView({ row: { data: data } })
        }, this.props);
    }

    // UNSAFE_componentWillReceiveProps = (nextProps) => {
    //     const AbsentVoucherID1 = this.getInfo(nextProps).AbsentVoucherID;
    //     if(AbsentVoucherID1 && this.AbsentVoucherID !== AbsentVoucherID1){
    //         this.AbsentVoucherID=AbsentVoucherID1;
    //         this.checkNotify(AbsentVoucherID1);
    //     }
    // };
    //
    // checkNotify = (AbsentVoucherID) => {
    //     if(AbsentVoucherID) {
    //         this.onView({row:{data:{AbsentVoucherID:AbsentVoucherID}}});
    //         browserHistory.push(Config.getRootPath() + 'W75F2010');
    //         this.AbsentVoucherID=null;
    //     }
    // };
    //
    // getInfo = (props) => {
    //     const {location} = props ? props : {};
    //     const url = new window.URLSearchParams(window.location.search);
    //     if (url && url.get('voucher_id')) {
    //         return {AbsentVoucherID: url.get('voucher_id')};
    //     } else if (location && location.state) {
    //         return {
    //             AbsentVoucherID: location.state.voucher_id,
    //         }
    //     } else {
    //         return {};
    //     }
    // };

    loadCboEmployees = (isReset) => {
        const param = {
            Type: "EmployeeID",
            FormID: "W75F2010",
            Language: Config.language || '84',
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch
        };
        this.loading.cboEmployees = true;
        this.setState({loading: this.loading});
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.loading.cboEmployees = false;
            this.setState({loading: this.loading});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboEmployees} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadCboProjects = (isReset)=>{
      const param = {
          FormID : "W75F2010",
          skip: this.filterCboProject.skip,
          limit: this.filterCboProject.limit,
          search: this.filterCboProject.strSearch
      };
        this.loading.cboProjects = true;
        this.setState({loading: this.loading});
      this.props.generalActions.getCboProjects(param, (error, data) => {
          this.loading.cboProjects = false;
          this.setState({loading: this.loading});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
          if (data) {
              const {dataCboProjects} = this.state;
              const rows = data && data.rows ? data.rows : data;
              const total = data && data.total ? data.total : data.length;
              this.setState({
                  dataCboProjects: {
                      rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                      total: total
                  }
              })
          }
        });
    };

  loadDataGrid = (VoucherID)=>{
        const {voucher_id} = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : voucher_id;
        const {Employee,DateFrom,DateTo,AppStatusID,Project,PeriodTime,VoucherDate,skip,limit} = this.filter;
        const param = {
            TransID: VoucherID || "",
            FormID : "W75F2010",
            Language:Config.language || 84,
            EmployeeID:Employee?.EmployeeID || "",
            LeaveDateFrom:DateFrom,
            LeaveDateTo:DateTo,
            VoucherDate:VoucherDate,
            ProjectID:Project?.ProjectID || "",
            AppStatusID:AppStatusID,
            Period:PeriodTime,
            skip:skip,
            limit: limit,
        };
      this.setState({gridLoading : true});
      this.props.W75F2010Actions.loadGrid(param, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
          this.setState({gridLoading : false})
        });
    };

    loadCboPeriod = () =>{
        const param = {
            DivisionID: Config.getDivisionID(),
        };

        this.props.W75F2010Actions.loadPeriod(param, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });

    };

    loadCboAppStatus = () =>{
        const param = {
            FormID:"W75F2010",
            Language:Config.language || 84,
        };

        this.props.W75F2010Actions.loadAppStatus(param, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    onChangePage = (page)=>{
        this.filter.skip = page * this.filter.limit;
        this.loadDataGrid();
    };

    onChangePerPage = (perPage)=>{
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadDataGrid();
    };

    onCloseModal = (isReload = false) => {
        if(isReload) this.loadDataGrid();
        this.setState({
            showW75F2010Popup: false
        });
    };

    render() {
        const {getDataGrid} = this.props;
        const {iPermission, gridLoading, showW75F2010Popup, mode, rowData} = this.state;
        const {skip,limit} = this.filter;
        if (!iPermission) return null;

        return(
            <React.Fragment>
                <Modal open={showW75F2010Popup} maxWidth={"md"} fullWidth={true}
                       title={Config.lang("DHR_Lap_de_xuat_dieu_chinh_thu_nhap")}
                       onClose={() => this.onCloseModal()}>
                    <W75F2010Popup mode={mode} data={rowData} onClose={this.onCloseModal}/>
                </Modal>
                <div className={"hidden"}>{this.renderFilter()}</div>
                <ActionToolbar
                    title={Config.lang("DHR_Lap_de_xuat_dieu_chinh_thu_nhap")}
                >
                        <ButtonGeneral
                            name={Config.lang('DHR_Them')}
                            typeButton={'add'}
                            style={{textTransform: 'uppercase'}}
                            size={"large"}
                            onClick={this.onAdd}
                        />
                </ActionToolbar>
                <GridContainer
                    style={{border: 'none'}}
                    dataSource={getDataGrid && getDataGrid.rows}
                    showRowLines={true}
                    showBorders={false}
                    showColumnLines={false}
                    showColumnHeaders={false}
                    noDataText={Config.lang("DHR_No_data")}
                    onCellClick={this.onDetail}
                    onDbRowClick={this.onEdit}
                    loading={gridLoading}
                    hoverStateEnabled={true}
                    height={Config.getHeightGrid()}
                    totalItems={getDataGrid && getDataGrid.total}
                    itemPerPage={limit}
                    skipPerPage={skip}
                    typePaging={"remote"}
                    onChangePage={this.onChangePage}
                    onChangePerPage={this.onChangePerPage}
                >
                    <Column cellRender={this.renderEmpProfile}/>
                    <Column cellRender={this.renderInfo}/>
                    <Column cellRender={this.renderStatus}/>
                    <Column cellRender={this.renderAction}/>
                </GridContainer>
            </React.Fragment>
        )
    }
}

export default compose(connect((state) => ({
    getCboEmployees: state.general.getCboEmployees,
    getCboProjects: state.general.getCboProjects,
    getDataGrid: state.W75F2010.getDataGrid,
    getCboPeriod: state.W75F2010.getCboPeriod,
    getCboAppStatus: state.W75F2010.getCboAppStatus,
    getCboAbsentType : state.W75F2010.getCboAbsentType,
}), (dispatch) => ({
    generalActions: bindActionCreators(generalActions, dispatch),
    W75F2010Actions: bindActionCreators(W75F2010Actions,dispatch)
})),withStyles(styles))(W75F2010);
