/**
 * @copyright 2019 @ DigiNet
 * @author ANHTAI
 * @create 05/18/2020
 * @Example
 */

import React, { PureComponent } from 'react';
import { connect } from "react-redux";
import Config from "../../../../config";
import withStyles from "@material-ui/core/styles/withStyles";
import {Avatar} from '@material-ui/core';
import {Tooltip} from 'diginet-core-ui/components';
import { bindActionCreators, compose } from "redux";
import * as generalActions from "../../../../redux/general/general_actions";
import _ from "lodash";
import './w75f2002.scss';
import InfoIcon from '@material-ui/icons/Info';

const styles = theme => ({
    tooltip: {
        whiteSpace: "pre-line",
        fontSize: "1rem"
    },
    iconBar: {
        display: 'flex',
        width: '100%',
        justifyContent:'space-around'
    },
    blockIcon: {
        // width: '25%',
        display:'flex',
        flexDirection:'column',
        alignItems: 'center'
    },
    inBlock: {
        textAlign: 'center',
        position:'relative',
        height: 40,
        width: 40
    },
    iconLeave: {
        margin: 'auto',
        marginBottom: '7px',
        width: theme.spacing(5),
        height: theme.spacing(5),
        fontSize: '16px',
        lineHeight: '19px',
        fontWeight: '600'
    },
    iconInfo: {
        position: "absolute",
        bottom: -7,
        right: -7,
        [theme.breakpoints.down(721)]: {
            bottom: -4,
            right: -4
        }
    },
    leave: {
        backgroundColor: theme.palette.info.main
    },
    leaved: {
        backgroundColor: theme.palette.danger.main
    },
    leaveRest: {
        backgroundColor: theme.palette.success.main
    }
});

class W75F2002 extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            toggleTable: false,
            LeaveCycle: 0,
            dataGeneralInformation: []
        }
    }

    async componentDidMount() {
        const { employeeID } = this.props;
        this.loadGeneralInformation(employeeID);
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.employeeID) {
            this.setState({ dataGeneralInformation: [] })
            this.loadGeneralInformation(nextProps.employeeID);
        }
    };

    loadGeneralInformation = (EmployeeID) => {
        const { LeaveCycle } = this.state;
        const param = {
            LeaveCycle,
            DivisionID: Config.getDivisionID(),
            TranMonth: Config.getHRTransMonth(),
            TranYear: Config.getHRTransYear(),
            Language: Config.language || '84',
            EmployeeID,
        };
        this.setState({ loading: true });
        this.props.generalActions.getGridGeneralInformation(param, (error, data) => {
            if (error) {
                this.setState({ loading: false });
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            if (data) {
                this.setState({
                    dataGeneralInformation: data,
                    loading: false
                })
            }
        });
    };

    render() {
        const { classes } = this.props;
        const { dataGeneralInformation } = this.state;
        const { BacklogLeave, SeniorityLeave, AnnualLeave, UsedLeave, RemainLeave, CompensationLeave,
            UsedBackLog,
            UsedSeniority,
            UsedAnnual,
            UsedCompen,
            RemainBackLog,
            RemainSeniority,
            RemainAnual,
            RemainCompen,
            ExpiredLeave
        } = dataGeneralInformation;

        const viewTooltip = (txt1 = 0, txt2 = 0, txt3 = 0, txt4 = 0, txt5 = undefined) => {
            return <div>{Config.lang("Phep_Ton")}: {txt1}<br/>
              {Config.lang("Phep_tham_nien",)}: {txt2}<br/>
              {Config.lang("Phep_nam")}: {txt3}<br/>
              {Config.lang("Phep_bu")}: {txt4}<br/>
                {!_.isUndefined(txt5) ? `${Config.lang("Phep_het_han_chua_su_dung")}: ${txt5}` : ""}
            </div>;
        };

        const isTotal = BacklogLeave + SeniorityLeave + AnnualLeave + CompensationLeave;
        const data = [
            {
                name: Config.lang("Tong_phep"),
                value: !isNaN(isTotal) ? isTotal : undefined,
                className: classes.leave + " " + classes.iconLeave,
                data: viewTooltip(BacklogLeave,SeniorityLeave,AnnualLeave,CompensationLeave)
            },
            {
                name: Config.lang("Phep_da_nghi_/_het_han"),
                value: UsedLeave,
                className: classes.leaved + " " + classes.iconLeave,
                data: viewTooltip(UsedBackLog,UsedSeniority,UsedAnnual,UsedCompen,ExpiredLeave)
            },
            {
                name: Config.lang("Phep_con_lai"),
                value: RemainLeave,
                className: classes.leaveRest + " " + classes.iconLeave,
                data: viewTooltip(RemainBackLog,RemainSeniority,RemainAnual,RemainCompen)
            },

        ];

        return (
            <>
                {!_.isEmpty(dataGeneralInformation) &&
                    <div className={classes.iconBar}>
                        {data.map((item,i) => {
                            return (
                              <div key={i} className={classes.blockIcon}>
                                  <Tooltip title={item.data}
                                           arrow={true}
                                           textAlign={'left'}>
                                      <div className={classes.inBlock}>
                                          <Avatar className={item.className}>
                                              {item.value}
                                          </Avatar>
                                          <InfoIcon color="primary"
                                                    className={classes.iconInfo}
                                                    fontSize="small" />
                                      </div>
                                  </Tooltip>
                                  {item.name}
                              </div>
                            )
                        })}
                    </div>
                }
            </>
        );
    }
}


export default compose(connect((state) => ({
}), (dispatch) => ({
    generalActions: bindActionCreators(generalActions, dispatch),
})), withStyles(styles))(W75F2002);
