/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/19/2019
 * @Example
 */
import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import * as W75F2000Actions from "../../../../redux/W75/W75F2000/W75F2000_actions";
import {bindActionCreators, compose} from "redux";
import Config from "../../../../config";
import {Row, Col, FormGroup} from "react-bootstrap";
import ButtonGeneral from "../../../common/button/button-general";
import withStyles from "@material-ui/core/styles/withStyles";
import {Combo, MForm, TextField} from "../../../common/form-material";
import * as generalActions from "../../../../redux/general/general_actions";
import W75F2002 from "./../W75F2002/W75F2002";
import {FormControl, InputLabel, Switch, TextField as TextFieldM} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import moment from "moment";
import DateBoxPicker from "../../../common/form-material/date-box";
import Attachments from "../../../common/attachments/attachments";
import IconButton from "@material-ui/core/IconButton";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import CDN from "../../../CDN";
import Modal from "../../../common/modal/modal";
import {Column} from "devextreme-react/data-grid";
import GridContainer from "../../../grid-container/grid-container";
import AnimateHeight from "react-animate-height";
import _ from "lodash";
import TextBox from 'devextreme-react/text-box';
import FormHelperText from "@material-ui/core/FormHelperText";
import Icons from "../../../common/icons";
import InputMask from "react-input-mask";

const styles = {
    avatar: {
        width: 30,
        height: 30,
        borderRadius: "50%",
        border: "1px solid #ddd",
        overflow: "hidden",
        marginRight: 8,
    },
    imgAvatar: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain",
    },
    chip: {
        fontSize: "1rem",
        fontWeight: 400,
        border: "none",
        backgroundColor: "#EBEBEB",
    },
    label: {
        fontSize: "1rem",
        fontWeight: 200,
        color: "#757575",
    },
    customHrTag: {
        // transform: "translateX(-5%)",
        width: "100%",
        // marginTop: "8px",
    },
    styleTimePicker: {
        '& .MuiInputBase-input': {
            textAlign: "center"
        }
    },
    textError: {
        '& .MuiFormHelperText-root': {
            marginTop: 0,
            position: "relative",
            top: 0
        }
    },
    styleErrorMask: {
        '& .dx-texteditor-container': {
            '& .dx-texteditor-input-container::after': {
                content: " ",
                backgroundColor: "transparent !important",
            }
        }

    },
    inputMask: {
        '& .MuiInputBase-root': {
            '& .MuiIconButton-root': {
                padding: "0px 12px 0px 0px"
            }
        }

    }
};

class W75F2000Popup extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            showW75F2000Popup: false,
            existsLeaveTypeID: false,
            isRegisterType: 0,
            iPermissionCb: 0,
            dataGrid: [],
            leaveTypeID: "",
            quantity: "",
            reason: "",
            attachment: "",
            dateFrom: null,
            dateTo: null,
            firstHoursLeave: null,
            lastHoursLeave: null,
            totalHours: "",
            isMultipleDate: false,
            disabled: false,
            disabledSave: false,
            dataOldAttachments: [],
            employee: null,
            dataCboEmployees: {
                rows: [],
                total: 0,
            },
            dataDetail: [],
            dataHistory: {rows: [], total: 0},
            employeeLoading: false,
            error: {},
            loading: false,
            gridLoading: false,
            fullDateFrom: 1,
            fullDateTo: 1,
            toggleTable: false,
            toggleTable1: false,
            employeeID: "",
            isMinUnitRecordLeave: 0,
            isMinUnitQuantity: 0,
            errorMask: false,
            checkOneStDayQuan: false,
            checkMaskTotalHour: false,
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 50,
        };
        this.gridHistory = {
            ref: null,
            skip: 0,
            limit: 10,
        };
        this.attachments = [];
        this.deletedFile = [];
        this.detailLoaded = {
            EmployeeID: "",
            LeaveTypeID: "",
            DateFrom: null,
            DateTo: null,
            FullDateFrom: 0,
            FullDateTo: 0,
            TotalHours: "",
            FirstHoursLeave: null,
            LastHoursLeave: null,
        };
        this.isFirstLoadHistory = false;
        this.isChangeMultipleDate = false;
        this.dataNameRequireFields = [
            {id: "Reason", name: Config.lang("DHR_Ly_do")},
            {id: "Attachment", name: Config.lang("DHR_Dinh_kem")},
        ];
    }

    checkInputHoursLeave = (hour = "", isTotalHour = false) => {
        const dataHour = _.chain(hour).split("");
        return !!hour && isTotalHour ? dataHour.filter(n => n === " ").size().value() === 1 : !dataHour.pull(":").some(n => n === "_").value();
    };

    loadPermissionCboEmployee = async () => {
        await this.props.generalActions.getPermission(
            "W09F5660",
            (isPer) => {
                this.setState({iPermissionCb: isPer});
            },
            true,
        );
    };

    loadCboEmployees = (isReset) => {
        const param = {
            HostID: "",
            Type: "EmployeeID",
            FormID: "W75F2000",
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch,
        };
        this.setState({employeeLoading: true});
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.setState({employeeLoading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboEmployees} = this.state;
                const _d = data && data.rows ? data.rows : [];
                const total = data && data.total ? data.total : 0;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? _d : dataCboEmployees.rows.concat(_d),
                        total: total,
                    },
                });
            }
        });
    };

    getUnitRecordAndUnitQuantity = () => {
        this.props.w75F2000Actions.getMinUnitRecordLeave((error, data) => {
            if (data && data.IsMinUnitRecordLeave) {
                this.setState({isMinUnitRecordLeave: data.IsMinUnitRecordLeave});
            }
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
        this.props.w75F2000Actions.getMinUnitQuantity((error, data) => {
            if (data && data.IsMinUnitRecordLeave) {
                this.setState({isMinUnitQuantity: data.IsMinUnitRecordLeave});
            }
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadRequiredFields = () => {
        const params = {
            FormID: "W75F2000",
            ModuleID: "15",
            CheckMode: 0,
        };
        this.setState({loading: true});
        this.props.generalActions.getRequiredFields(params, (error) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadSetting = () => {
        this.props.generalActions.loadSetting(err => {
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
        })
    };

    getForm = () => {
        const {mode, data} = this.props;
        const param = {
            Language: Config.language || 84,
            TransID: data && data.TransID ? data.TransID : "",
        };
        this.setState({loading: true});
        this.props.w75F2000Actions.getForm(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const user = Config.getUser({EmployeeID: data.EmployeeID});
                this.setState(
                    {
                        employeeID: data.EmployeeID,
                        employee: user ? user : data,
                        leaveTypeID: data.LeaveTypeID,
                        quantity: data.Quantity,
                        reason: data.Reason,
                        dateFrom: data.LeaveDateFrom,
                        dateTo: data.LeaveDateTo,
                        fullDateTo: data.LastAbsDayQuan,
                        fullDateFrom: data.FirstAbsDayQuan,
                        isRegisterType: data.IsRegisterType,
                        firstHoursLeave: data?.FirstHourLeave || null,
                        lastHoursLeave: data?.LastHourLeave || null,
                        totalHours: this._convertHour(data.TotalHours),
                        isMultipleDate: Boolean(data.IsMultipleDate),
                    },
                    () => {
                        this.getGridDetail();
                        if (mode !== "view") {
                            this.checkRegisterType();
                        }
                    },
                );
            }
            this.setState({loading: false});
        });
    };

    loadHistory = (EmployeeID) => {
        const {skip, limit} = this.gridHistory;
        const param = {
            TranMonth: Config.getHRTransMonth(),
            TranYear: Config.getHRTransYear(),
            EmployeeID,
            skip,
            limit,
        };
        this.setState({loading: true});
        this.props.w75F2000Actions.getGridHistory(param, (error, data) => {
            this.setState({loading: false});
            this.isFirstLoadHistory = false;
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                this.setState({
                    dataHistory: data,
                });
            }
        });
    };

    getGridDetail = () => {
        const {mode, data, loadSetting} = this.props;
        const {
            employee, leaveTypeID, quantity, dateFrom, dateTo, fullDateFrom,
            fullDateTo, isMultipleDate, totalHours, firstHoursLeave, lastHoursLeave
        } = this.state;
        let type = mode === "view" ? "Edit" : "Add";
        const EmployeeID = _.get(employee, "EmployeeID", "") || _.get(data, "EmployeeID", "");
        const LeaveTypeID = leaveTypeID || _.get(data, "LeaveTypeID", "");
        const TransID = _.get(data, "TransID", "");
        let checkOneCase = loadSetting[0]?.IsHourLeave === 0 || isMultipleDate;
        const checkDateTo = loadSetting[0]?.IsHourLeave === 0 ||
            (loadSetting[0]?.IsHourLeave === 1 && loadSetting[0]?.IsTotalHour === 0) ||
            (loadSetting[0]?.IsHourLeave === 1 && loadSetting[0]?.IsTotalHour === 1);
        const checkLoadGridDetailEdit = mode === "view" && this.isChangeMultipleDate && !isMultipleDate;
        // const checkCaseTwoAndThree = (loadSetting[0]?.IsHourLeave === 1 && loadSetting[0]?.IsTotalHour  === 0) ||
        //     (loadSetting[0]?.IsHourLeave === 1 && loadSetting[0]?.IsTotalHour  === 1);
        if (_.isEmpty(LeaveTypeID) || _.isEmpty(EmployeeID) || (checkDateTo ? false : _.isEmpty(dateTo)) || _.isEmpty(dateFrom)) return false;
        const checkTotalHours = _.isEqual(this.detailLoaded.TotalHours, totalHours);
        const checkFirstHoursLeave = _.isEqual(this.detailLoaded.FirstHoursLeave, firstHoursLeave);
        const checkLastHoursLeave = _.isEqual(this.detailLoaded.LastHoursLeave, lastHoursLeave);
        if (
            EmployeeID === this.detailLoaded.EmployeeID &&
            LeaveTypeID === this.detailLoaded.LeaveTypeID &&
            dateFrom === this.detailLoaded.DateFrom &&
            (!checkLoadGridDetailEdit && dateTo === this.detailLoaded.DateTo) &&
            fullDateFrom === this.detailLoaded.FullDateFrom &&
            fullDateTo === this.detailLoaded.FullDateTo
            && checkTotalHours && checkFirstHoursLeave && checkLastHoursLeave
        ) {
            return;
        }
        this.detailLoaded = {
            EmployeeID: EmployeeID,
            LeaveTypeID: LeaveTypeID,
            DateFrom: dateFrom,
            DateTo: dateTo ? dateTo : dateFrom,
            FullDateFrom: fullDateFrom,
            FullDateTo: fullDateTo,
            TotalHours: totalHours,
            FirstHoursLeave: firstHoursLeave,
            LastHoursLeave: lastHoursLeave,
        };
        const params = {
            EmployeeID,
            TransID,
            DivisionID: Config.getDivisionID(),
            Quantity: quantity,
            FirstAbsDayQuan: checkOneCase ? (fullDateFrom ? fullDateFrom : 1) : quantity,
            LastAbsDayQuan: (mode === "view" && this.isChangeMultipleDate && !isMultipleDate) ? 1 :
                (fullDateTo ? fullDateTo : 1),
            LeaveDateFrom: dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : null,
            LeaveDateTo: (mode === "view" && !isMultipleDate) ?
                (dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : null) :
                dateTo ? dateTo : (dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : null),
            LeaveTypeID: leaveTypeID,
            TranMonth: Config.getHRTransMonth(),
            TranYear: Config.getHRTransYear(),
            Language: Config.language || "84",
        };
        this.setState({gridLoading: true, loading: true});
        this.props.w75F2000Actions[`getGridDetail${type}`](params, (error, data) => {
            this.setState({gridLoading: false, loading: false});
            this.isFirstLoadGridDetail = false;
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (!_.isEmpty(data) && _.some(data, {LeaveTypeID: ""})) {
                this.setState({existsLeaveTypeID: false});
            } else {
                this.setState({existsLeaveTypeID: true});
            }
            if (data) {
                this.setState({dataDetail: data, disabledSave: false});
            }
            this.setState({toggleTable: true});
        });
    };

    loadAttachments = () => {
        const {data} = this.props;
        const isRegisterType = _.get(data, "IsRegisterType", "");
        const param = {
            KeyID: isRegisterType ? _.get(data, "LinkTransID", "") : _.get(data, "TransID"),
        };
        if (_.isEmpty(param.KeyID)) {
            Config.popup.show("INFO", Config.lang("DHR_KeyID_dang_rong_hoac_thieu_khong_the_tai_danh_sach_dinh_kem"));
            return false;
        }
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data && data.length > 0) {
                this.setState({
                    dataOldAttachments: data,
                });
            }
        });
    };

    checkRegisterType = (loadGridDetail = false) => {
        const {
            employee,
            leaveTypeID,
            quantity,
            dateFrom,
            dateTo,
            isRegisterType,
            fullDateFrom,
            fullDateTo,
            firstHoursLeave,
            lastHoursLeave,
            totalHours,
            isMultipleDate
        } = this.state;
        const {fromW75F2005, loadSetting, mode} = this.props;
        let checkOneCase = loadSetting[0]?.IsHourLeave === 0 || isMultipleDate;
        let checkCaseTwoandThree = (loadSetting[0]?.IsHourLeave === 1 && loadSetting[0]?.IsTotalHour === 0) ||
            (loadSetting[0]?.IsHourLeave === 1 && loadSetting[0]?.IsTotalHour === 1);
        if (!employee) return false;
        const param = {
            EmployeeID: employee && employee.EmployeeID ? employee.EmployeeID : "",
            Quantity: quantity,
            LeaveDateFrom: dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : null,
            LeaveDateTo: (mode === "view" && !isMultipleDate) ?
                (dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : null) :
                (dateTo ? dateTo : dateFrom && moment(dateFrom).format("YYYY-MM-DD")),
            Mode: 0,
            Language: Config.language || "84",
            LeaveTypeID: leaveTypeID,
            FormID: "W75F2000",
            IsRegisterType: isRegisterType,
            OnestAbsDayQuan: checkOneCase ? (fullDateFrom ? fullDateFrom : 1) : quantity,
            LastAbsDayQuan: (mode === "view" && this.isChangeMultipleDate && !isMultipleDate) ? 1 :
                (fullDateTo ? fullDateTo : 1),
            FirstHoursLeave: firstHoursLeave ? firstHoursLeave : null,
            LastHoursLeave: lastHoursLeave ? lastHoursLeave : null,
            TotalHours: !isMultipleDate ? totalHours : "",
            IsMultipleDate: _.toNumber(isMultipleDate)
        };

        const checkTotalHours = !isMultipleDate ? (totalHours && this.state.errorMask) : false;
        if (checkTotalHours) {
            return false;
        }

        this.props.w75F2000Actions.checkRegisterType(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                if (data.Status === 1) {
                    let message = data.Message || Config.lang("DHR_Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                }
                if (checkCaseTwoandThree) {
                    if (!isMultipleDate) {
                        if (data.Quantity > 1) {
                            // let message = data.Message || Config.lang("DHR_Khong_duoc_phep_nghi_qua_mot_ngay");
                            // Config.popup.show("INFO", message);
                            this.setState({checkOneStDayQuan: true});
                        } else {
                            this.setState({checkOneStDayQuan: false});
                        }
                    }
                }

                this.setState({
                    quantity: _.isUndefined(fromW75F2005) ? data.Quantity : this.state.quantity,
                });
            }
            let checkLoadGridDetail = checkCaseTwoandThree && !isMultipleDate &&
                ((mode === "view" && this.isChangeMultipleDate && !isMultipleDate) ? 0 : data.Quantity > 1);
            if (loadGridDetail) {
                if (!checkLoadGridDetail) {
                    this.getGridDetail();
                }
            }
        });
    };

    handleChange = async (key, e) => {
        const {dateTo, dateFrom} = this.state;
        const isEqualDate = moment(moment(dateTo).format("YYYY-MM-DD")).isSame(moment(dateFrom).format("YYYY-MM-DD"));
        const value = _.get(e, "value", _.get(e, "target.value", _.get(e, "event.target.value", "")));
        if (!key) return false;
        switch (key) {
            case "fullDateFrom":
                this.setState({fullDateFrom: value}, () => {
                    this.checkRegisterType(true);
                });
                break;
            case "fullDateTo":
                this.setState({fullDateTo: value}, () => {
                    this.checkRegisterType(true);
                });
                break;
            case "DateFrom":
                if (isEqualDate) this.setState({fullDateTo: 1});
                this.setState({dateFrom: value}, () => {
                    this.checkRegisterType(true);
                });
                break;
            case "DateTo":
                if (isEqualDate) this.setState({fullDateTo: 1});
                this.setState({dateTo: value}, () => {
                    this.checkRegisterType(true);
                });
                break;
            case "LeaveDate":
                this.setState({
                    dateFrom: value,
                    dateTo: value,
                });
                break;
            case "EmployeeID":
                if (!value) return false;
                this.setState({employee: value}, () => {
                    this.loadHistory(value.EmployeeID);
                    this.setState({employeeID: value.EmployeeID});
                    this.checkRegisterType(true);
                });
                break;
            case "LeaveTypeID":
                this.setState({leaveTypeID: value}, () => this.getGridDetail());
                break;
            case "Reason":
                this.setState({reason: value});
                break;
            case "FirstHoursLeave":
                this.setState({firstHoursLeave: value}, () => {
                    if (this.checkInputHoursLeave(value)) {
                        this.setState({errorMask: false});
                        if (this.checkInputHoursLeave(_.get(this.state, "lastHoursLeave", ""))) this.checkRegisterType(true);
                    }
                });
                break;
            case "LastHoursLeave":
                this.setState({lastHoursLeave: value}, () => {
                    if (this.checkInputHoursLeave(value)) {
                        this.setState({errorMask: false});
                        if (this.checkInputHoursLeave(_.get(this.state, "firstHoursLeave", ""))) this.checkRegisterType(true);
                    }
                });
                break;
            case "TotalHours":
                let val = value;
                let dataCheckMask = val.split('');
                let dataTotalHour = [" ", "h", " ", " ", " ", "m"];
                if (!dataCheckMask.includes("h")) {
                    dataTotalHour.unshift(val);
                    val = dataTotalHour.join('');
                }
                if (dataCheckMask.length > 0) {
                    this.setState({checkMaskTotalHour: true}, () => {
                            this.setState({totalHours: val}, () => {
                                let dataCheckError = this.state.totalHours.split('');
                                dataCheckError.splice(3, 1);
                                if (!dataCheckError.some(val => val === " ")) {
                                    this.setState({errorMask: false});
                                }
                            });
                    });
                } else {
                    this.setState({checkMaskTotalHour: false});
                }
                if(this.checkInputHoursLeave(value, true)) this.checkRegisterType(true);
                e.component.option("isValid", true);
                break;
            default:
                break;
        }
    };

    async componentDidMount() {
        const {mode, data, edit, fromW75F2005} = this.props;
        const employeeID = _.get(data, "EmployeeID", "");
        await this.loadPermissionCboEmployee();
        if (this.state.iPermissionCb > 2) {
            this.loadCboEmployees();
        } else {
            const getUser = Config.getUser({EmployeeID: Config.getHREmployeeID()});
            this.setState({
                dataCboEmployees: getUser ? [getUser] : [],
                employee: getUser ? {...getUser} : null,
                employeeID: Config.getHREmployeeID(),
            });
        }
        this.getUnitRecordAndUnitQuantity();
        this.loadRequiredFields();
        this.loadSetting();
        if (mode === "view") {
            this.isFirstLoadHistory = true;
            this.getForm();
            // this.getGridDetail();
            this.loadAttachments();
            // this.setState({ employeeID: employeeID });
            if ((data && Number(data.AppStatusID) !== 0) || !edit) {
                this.setState({disabled: false});
            } else {
                if (this.state.iPermissionCb < 3 && !!_.isUndefined(fromW75F2005)) {
                    this.loadHistory(employeeID);
                }
            }
        } else {
            this.checkRegisterType();
        }
    }

    onChangePage = (page) => {
        const {employeeID} = this.state;
        this.gridHistory.skip = page * this.gridHistory.limit;
        this.loadHistory(employeeID);
    };

    onChangePerPage = (per) => {
        const {employeeID} = this.state;
        this.gridHistory.skip = 0;
        this.gridHistory.limit = per;
        this.loadHistory(employeeID);
    };

    onTypes = (e) => {
        const {disabled} = this.props;
        if (!e || disabled) return false;
        this.setState({isRegisterType: Number(e.target.value)}, () => {
            const {dateFrom, dateTo} = this.state;
            if (moment(dateFrom) > moment(dateTo)) {
                this.setState({dateFrom: dateTo});
            }
            this.checkRegisterType();
        });
    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    onUploading = (value) => {
        this.setState({uploading: value});
    };

    onChangeAttachments = (e) => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
            this.setState((prevState) => ({
                dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                }),
            }));
        }
    };

    setStateErrorText(objValue, key) {
        const {errorHelperText} = this.state;
        const stateError = key ? key : "error";
        this.setState({
            [stateError]: {
                ...errorHelperText,
                ...objValue,
            },
        });
        return Object.keys(objValue).length !== 0;
    }

    onSave = async () => {
        const {
            employee,
            leaveTypeID,
            quantity,
            dateFrom,
            dateTo,
            isRegisterType,
            reason,
            fullDateFrom,
            fullDateTo,
            dataOldAttachments,
            existsLeaveTypeID,
            firstHoursLeave,
            lastHoursLeave,
            totalHours,
            errorMask,
            isMultipleDate,
        } = this.state;
        const {mode, data, getRequiredFields, loadSetting} = this.props;
        let checkOneCase = loadSetting[0]?.IsHourLeave === 0 || isMultipleDate;
        let checkAllCase = loadSetting[0]?.IsHourLeave === 0 ||
            (loadSetting[0]?.IsHourLeave === 1 && loadSetting[0]?.IsTotalHour === 0) ||
            (loadSetting[0]?.IsHourLeave === 1 && loadSetting[0]?.IsTotalHour === 1);
        let checkCaseTwoandThree = (loadSetting[0]?.IsHourLeave === 1 && loadSetting[0]?.IsTotalHour === 0) ||
            (loadSetting[0]?.IsHourLeave === 1 && loadSetting[0]?.IsTotalHour === 1);
        let checkQuantity = (!isMultipleDate && checkCaseTwoandThree && quantity > 1) || quantity < 0;
        const listData = Object.keys(this.state);
        let dataRequired = ["EmployeeID", "LeaveTypeID", "LeaveDateFrom", "LeaveDateTo"];
        let checkFieldSaveData = [];
        const arrAttachments = this._getAttachments();
        const Reason = !isRegisterType ? reason : "";
        const ReasonCancel = isRegisterType ? reason : "";
        const TransID = mode !== "add" ? _.get(data, "TransID", "") : "";
        const EmployeeID = _.get(employee, "EmployeeID", "");
        const filterAttachment = getRequiredFields && getRequiredFields.filter((item) => item.SqlFieldName === "Attachment");
        getRequiredFields &&
        getRequiredFields.forEach((field) => {
            if (
                (field.ValidMode &&
                    field.ValidMode === "O" &&
                    field.SqlFieldName &&
                    listData.indexOf(field.SqlFieldName.toLowerCase()) > -1 &&
                    dataRequired.indexOf(field.SqlFieldName) < 0) ||
                (filterAttachment[0].ValidMode === "O" && _.isEmpty(this.attachments) && _.isEmpty(dataOldAttachments)
                    ? field.SqlFieldName === "Attachment"
                    : "")
            ) {
                dataRequired.push(field.SqlFieldName);
            }
            if (field.ValidMode &&
                field.ValidMode === "W" &&
                listData.indexOf(field.SqlFieldName.toLowerCase()) > -1) {
                if (_.isEmpty(this.state[field.SqlFieldName.toLowerCase()])) {
                    checkFieldSaveData.push(field.SqlFieldName);
                    if (field.SqlFieldName === "Attachment" && (!_.isEmpty(dataOldAttachments) || !_.isEmpty(this.attachments))) {
                        checkFieldSaveData = checkFieldSaveData.filter(f => f !== "Attachment");
                    }
                }
            }
        });
        const param = {
            TransID,
            EmployeeID,
            DivisionID: Config.getDivisionID(),
            Quantity: quantity,
            LeaveDateFrom: dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : null,
            LeaveDateTo: (mode === "view" && !isMultipleDate) ?
                (dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : null)
                : dateTo ? moment(dateTo).format("YYYY-MM-DD") :
                    (dateFrom ? moment(dateFrom).format("YYYY-MM-DD") : null),
            FirstAbsDayQuan: checkOneCase ? fullDateFrom : quantity,
            LastAbsDayQuan: (mode === "view" && this.isChangeMultipleDate && !isMultipleDate) ? 1 :
                (fullDateTo ? fullDateTo : 1),
            FirstHoursLeave: firstHoursLeave ? firstHoursLeave : null,
            LastHoursLeave: lastHoursLeave ? lastHoursLeave : null,
            TotalHours: totalHours,
            Language: Config.language || "84",
            LeaveTypeID: leaveTypeID,
            FormID: "W75F2000",
            IsRegisterType: isRegisterType,
            IsMultipleDate: Number(isMultipleDate),
            Reason,
            ReasonCancel,
            arrAttachment: JSON.stringify(arrAttachments),
        };
        const validateRules = [];
        dataRequired &&
        dataRequired.forEach((item) => {
            let rule;
            const normalCharItem = item.toLowerCase();
            switch (item) {
                case "EmployeeID":
                    rule = {
                        name: "EmployeeID",
                        rules: ["isRequired"],
                        value: employee && employee.EmployeeID ? employee.EmployeeID : "",
                    };
                    break;
                case "LeaveDateFrom":
                    rule = {
                        name: "LeaveDateFrom",
                        rules: ["isRequired"],
                        value: dateFrom ? dateFrom : "",
                    };
                    break;
                case "LeaveDateTo":
                    if (checkAllCase) {
                        rule = {};
                    } else {
                        rule = {
                            name: "LeaveDateTo",
                            rules: ["isRequired"],
                            value: dateTo ? dateTo : "",
                        };
                    }

                    break;
                case "LeaveTypeID":
                    rule = {
                        name: "LeaveTypeID",
                        rules: ["isRequired"],
                        value: leaveTypeID ? leaveTypeID : "",
                    };
                    break;
                default:
                    rule = {
                        name: item,
                        rules: ["isRequired"],
                        value: this.state[normalCharItem],
                    };
                    break;
            }
            validateRules.push(rule);
        });
        const validateForm = MForm.formValidation(validateRules);
        if (this.setStateErrorText(validateForm)) return false; //Neu co loi validate
        if (!existsLeaveTypeID) {
            Config.popup.show("INFO", Config.lang("DHR_Tong_phep_khong_du_de_dang_ky_._Vui_long_kiem_tra_lai"));
            return;
        }
        if (checkQuantity) {
            return;
        }
        if (errorMask) {
            return;
        }
        if (checkFieldSaveData.length > 0) {
            const nameRequired = this.dataNameRequireFields.filter(val => checkFieldSaveData.indexOf(val.id) > -1);
            const arrFieldName = [];
            nameRequired && nameRequired.forEach((val) => {
                arrFieldName.push(val.name);
            });
            const nameField = (arrFieldName && arrFieldName.length > 0 && arrFieldName.join(', ')) || "";
            Config.popup.show("YES_NO", Config.lang("DHR_Con_thong_tin_truong") + " " + nameField + " " +
                Config.lang("DHR_Ban_chua_nhap.Ban_co_muon_bo_sung_khong"), null, () => {
                this._onSave(mode, isRegisterType, param);
            }, null, null, Config.lang("DHR_co"), Config.lang("DHR_Luu"));
        } else {
            this._onSave(mode, isRegisterType, param);
        }
    };

    _onSave = (mode, isRegisterType, param) => {
        this.setState({loading: true});
        this.props.w75F2000Actions[mode === "add" ? "saveLeave" : isRegisterType ? "saveLeave" : "editLeave"](
            param,
            async (error, data) => {
                if (error) {
                    let message = Config.lang("DHR_Loi_chua_xac_dinh");
                    switch (error.code) {
                        case "F2000E002":
                            message = "Mode " + Config.lang("DHR_Bat_buoc");
                            break;
                        case "F2000E003":
                            message = "LeaveTypeID " + Config.lang("DHR_Bat_buoc");
                            break;
                        case "F2000E004":
                            message = "IsRegisterType " + Config.lang("DHR_Bat_buoc");
                            break;
                        case "F2000E001":
                            message = Config.lang("DHR_Luu_khong_thanh_cong");
                            break;
                        case "SYS013":
                            message = Config.lang("DHR_Dinh_dang_ngay_khong_dung");
                            break;
                        default:
                            break;
                    }
                    Config.popup.show("INFO", message);
                    this.setState({loading: false});
                    return false;
                }

                if (data) {
                    if (data && data.Status === 1) {
                        Config.popup.show("INFO", data.Message);
                        this.setState({loading: false});
                        return false;
                    } else {
                        this._removeCDN();
                        this.onClose(true);
                        Config.notify.show("success", Config.lang("DHR_Luu_thanh_cong"), 2000);
                    }
                }
                this.setState({loading: false});
            },
        );
    };
    _removeCDN = () => {
        if (this.deletedFile && this.deletedFile.length > 0) {
            this.deletedFile.forEach((e) => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1],
                    };
                    CDN.removeFile(params);
                }
            });
            this.deletedFile = [];
        }
    };

    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const {dataOldAttachments} = this.state;
        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : "",
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    handleShowMore = (e) => {
        const {mode} = this.props;
        if (mode === "view") {
            this.isChangeMultipleDate = true;
        }
        this.setState({isMultipleDate: e.target.checked}, () => {
            if (mode === "add") {
                this.setState({
                    quantity: "",
                    // reason: "",
                    dateFrom: null,
                    dateTo: null,
                    firstHoursLeave: null,
                    lastHoursLeave: null,
                    fullDateFrom: 1,
                    fullDateTo: 1,
                    totalHours: "",
                });
            }

        });
    };

    onClose = (isSaved) => {
        const {onClose} = this.props;
        if (onClose) onClose(isSaved);
    };

    _convertHour = (data) => {
        if (!data) return null;
        let hour = data.split(" ");
        if (hour.length > 1) {
            if (hour[0].replace("h", "").length === 1) {
                hour[0] = "0" + hour[0];
            }
            if (hour[1].replace("m", "").length === 1) {
                hour[1] = "0" + hour[1];
            }
            hour = hour.join("");
        }
        return hour;
    };

    _checkMaskRules = (data) => {
        if (!data) return null;
        return data?.toString().startsWith('2');
    };

    render() {
        let {getLeaveTypes, data, edit, mode, fromW75F2005, classes, loadSetting} = this.props;
        let {
            disabled,
            loading,
            error,
            isRegisterType,
            employee,
            leaveTypeID,
            quantity,
            dateFrom,
            dateTo,
            reason,
            dataOldAttachments,
            uploading,
            dataCboEmployees,
            employeeLoading,
            fullDateFrom,
            fullDateTo,
            toggleTable,
            toggleTable1,
            dataDetail,
            dataHistory,
            iPermissionCb,
            gridLoading,
            employeeID,
            isMinUnitRecordLeave,
            isMinUnitQuantity,
            disabledSave,
            isMultipleDate,
            firstHoursLeave,
            lastHoursLeave,
            totalHours,
            checkOneStDayQuan,
            checkMaskTotalHour
        } = this.state;
        const isEqualDate = moment(moment(dateTo).format("YYYY-MM-DD")).isSame(moment(dateFrom).format("YYYY-MM-DD"));
        const isFromW75F2005 = !_.isUndefined(fromW75F2005);
        const approval = _.get(data, "IsApprove", 0);
        const isRegister = isRegisterType === 0;
        const isEditMode = mode === "view";
        const allow = iPermissionCb > 2;
        const appStatusID = _.get(data, "AppStatusID", 0);
        let dataCBoUnitLeave = [0.25, 0.5, 0.75, 1];
        if (isMinUnitRecordLeave === 1) {
            switch (isMinUnitQuantity) {
                case 0.5:
                    dataCBoUnitLeave = _.pullAt(dataCBoUnitLeave, [1, 3]);
                    break;
                case 1:
                    dataCBoUnitLeave = [_.last(dataCBoUnitLeave)];
                    break;
                default:
                    break;
            }
        }
        const IsHourLeave = loadSetting && loadSetting[0]?.IsHourLeave;
        const IsTotalHour = loadSetting && loadSetting[0]?.IsTotalHour;
        const isShowColum = loadSetting[0]?.IsHourLeave === 0 ||
            (loadSetting[0]?.IsHourLeave === 1 && loadSetting[0]?.IsTotalHour === 0) ||
            (loadSetting[0]?.IsHourLeave === 1 && loadSetting[0]?.IsTotalHour === 1);
        const checkCaseTwoandThree = (loadSetting[0]?.IsHourLeave === 1 && loadSetting[0]?.IsTotalHour === 0) ||
            (loadSetting[0]?.IsHourLeave === 1 && loadSetting[0]?.IsTotalHour === 1);
        const checkRuleFirstHour = this._checkMaskRules(firstHoursLeave);
        const checkRuleLastHour = this._checkMaskRules(lastHoursLeave);
        //format data TotalHour
        let hour = totalHours && totalHours.substring(0, totalHours.indexOf("h") + 1).trim();
        let minute = totalHours && totalHours.substring(totalHours.indexOf("h") + 1, totalHours.indexOf("m") + 1).trim();
        const checkRuleTotalHour = this._checkMaskRules(hour + minute);
        const dataCheckTotalHour = totalHours ? totalHours?.split('') : [];
        totalHours = hour + minute ? hour + minute : null;
        return (
            <React.Fragment>
                <Modal.Content>
                    {employeeID ? (
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} style={{marginBottom: 7}}>
                                <W75F2002 employeeID={employeeID}/>
                                <hr className={classes.customHrTag}/>
                            </Col>
                        </Row>
                    ) : (
                        ""
                    )}
                    <div className={"row display_row align-center"}>
                        <Col xs={12} sm={12} md={8} lg={8}>
                            <Combo.Employees
                                required={true}
                                acceptCustomValue={true}
                                error={error && error["EmployeeID"]}
                                dataSource={dataCboEmployees}
                                displayExpr={"EmployeeName"}
                                valueExpr={"EmployeeID"}
                                keyExpr={"EmployeeID"}
                                subTextExpr={"EmployeeID"}
                                stylingMode={"underlined"}
                                disabled={loading || isFromW75F2005 ? false : !allow}
                                skip={this.filterCboEmployees.skip}
                                limit={this.filterCboEmployees.limit}
                                dropdownProps={{
                                    readOnly: disabled || isFromW75F2005,
                                }}
                                loading={employeeLoading}
                                shrink={true}
                                value={employee}
                                label={Config.lang("DHR_Nhan_vien")}
                                onValueChanged={(e) => this.handleChange("EmployeeID", e)}
                                onInputChanged={(e) => {
                                    this.filterCboEmployees.skip = 0;
                                    this.filterCboEmployees.strSearch = e.target.value;
                                    this.loadCboEmployees(true);
                                }}
                                onHoverAvatar={() => false}
                                onLoadMore={(e) => {
                                    this.filterCboEmployees.skip = e.skip;
                                    this.filterCboEmployees.limit = e.limit;
                                    this.loadCboEmployees();
                                }}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={4} lg={4}>
                            <FormControlLabel
                                className={"mgt15 pull-right"}
                                control={
                                    <Switch
                                        checked={isMultipleDate}
                                        onChange={this.handleShowMore}
                                        color={"primary"}
                                        disabled={disabled || isFromW75F2005}
                                        value="checkedA"
                                    />
                                }
                                label={Config.lang("DHR_Nhieu_ngay")}
                            />
                        </Col>
                    </div>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <Combo
                                label={Config.lang("DHR_Loai_phep")}
                                error={error && error["LeaveTypeID"]}
                                dataSource={
                                    isFromW75F2005
                                        ? [{
                                            LeaveTypeID: _.get(data, "LeaveTypeID", ""),
                                            LeaveTypeName: _.get(data, "LeaveTypeName", "")
                                        }]
                                        : getLeaveTypes
                                }
                                required={true}
                                displayExpr={"LeaveTypeName"}
                                valueExpr={"LeaveTypeID"}
                                stylingMode={"underlined"}
                                margin={"normal"}
                                disabled={loading || approval === 2}
                                selectProps={{
                                    readOnly: disabled || isFromW75F2005,
                                }}
                                value={leaveTypeID}
                                shrink={true}
                                onValueChanged={(e) => this.handleChange("LeaveTypeID", e)}
                            />
                        </Col>
                    </Row>
                    {/*<Row>*/}
                    {/*    <Col xs={12} sm={12} md={12} lg={12}>*/}
                    {/*        <RadioGroup defaultValue={0} aria-label="leaveType" row onChange={this.onTypes}>*/}
                    {/*            <FormControlLabel*/}
                    {/*                value="registerLeave"*/}
                    {/*                checked={isRegister}*/}
                    {/*                disabled={loading || !Number(appStatusID) === 2 || !isRegister}*/}
                    {/*                control={*/}
                    {/*                    <Radio*/}
                    {/*                        color={"primary"}*/}
                    {/*                        inputProps={{*/}
                    {/*                            disabled: disabled || !Number(appStatusID) === 2 || !isRegister || isFromW75F2005,*/}
                    {/*                        }}*/}
                    {/*                        value={0}*/}
                    {/*                    />*/}
                    {/*                }*/}
                    {/*                label={Config.lang("DHR_Dang_ky_nghi")}*/}
                    {/*            />*/}
                    {/*            <FormControlLabel*/}
                    {/*                value="abortLeave"*/}
                    {/*                checked={!isRegister}*/}
                    {/*                disabled={loading || !Number(appStatusID) === 2 || Number(appStatusID) === 0}*/}
                    {/*                control={*/}
                    {/*                    <Radio*/}
                    {/*                        color={"primary"}*/}
                    {/*                        inputProps={{*/}
                    {/*                            disabled:*/}
                    {/*                                disabled || !Number(appStatusID) === 2 || Number(appStatusID) === 0 || isFromW75F2005,*/}
                    {/*                        }}*/}
                    {/*                        value={1}*/}
                    {/*                    />*/}
                    {/*                }*/}
                    {/*                label={Config.lang("DHR_Huy_phep")}*/}
                    {/*            />*/}
                    {/*        </RadioGroup>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Row>
                        <Col xs={12} sm={12} md={!isMultipleDate && checkCaseTwoandThree ? 6 : 4}
                             lg={!isMultipleDate && checkCaseTwoandThree ? 6 : 4}>
                            <DateBoxPicker
                                error={error && error["LeaveDateFrom"]}
                                label={Config.lang("DHR_Tu_ngay")}
                                stylingMode={"underlined"}
                                margin={"normal"}
                                shrink={true}
                                max={(mode === "view" && this.isChangeMultipleDate && !isMultipleDate) ? null : dateTo ? moment(dateTo).format("YYYY-MM-DD") : null}
                                width={"100%"}
                                value={dateFrom ? moment(dateFrom).format("MM/DD/YYYY") : null}
                                disabled={loading || approval === 2 || !isRegister}
                                acceptCustomValue={true}
                                required={true}
                                dateBoxProps={{
                                    readOnly: disabled || isFromW75F2005,
                                }}
                                onValueChanged={(e) => this.handleChange("DateFrom", e)}
                            />
                        </Col>
                        {!isMultipleDate && <>
                            {IsHourLeave === 1 && <Col xs={12} sm={12} md={3} lg={3}>
                                {IsTotalHour === 1 ? (
                                    <FormControl variant={"standard"}
                                                 margin={"normal"}
                                                 error={this.state.errorMask}
                                                 fullWidth={true}>
                                        <InputLabel
                                            shrink={true}
                                        >
                                            {""}
                                        </InputLabel>
                                        <div style={{marginTop: 14}}>
                                            <TextBox
                                                mask={checkMaskTotalHour ? "sxh ngm" : ""}
                                                placeholder={checkMaskTotalHour ? "" : "02h 30m"}
                                                maskRules={{
                                                    's': /[0-2]/,
                                                    'x': checkRuleTotalHour ? /[0-3]/ : /[0-9]/,
                                                    'n': /[0-9]/,
                                                    'g': /[0-9]/,
                                                }}
                                                className={classes.styleErrorMask}
                                                disabled={disabled || isFromW75F2005}
                                                maskChar={" "}
                                                maskInvalidMessage={""}
                                                buttons={[
                                                    {
                                                        location: "before",
                                                        name: "clock",
                                                        options: {
                                                            icon: 'clock',
                                                            stylingMode: 'text',
                                                        }
                                                    }
                                                ]}
                                                value={totalHours}
                                                onInput={e => this.handleChange("TotalHours", e)}
                                                onFocusOut={(e) => {
                                                    if (!e) return false;
                                                    dataCheckTotalHour.splice(3, 1);
                                                    let check = dataCheckTotalHour.some(val => val === " ");
                                                    if (check) {
                                                        this.setState({errorMask: true});
                                                    } else {
                                                        this.setState({errorMask: false});
                                                    }
                                                    e.component.option("isValid", true);
                                                }}
                                            />
                                        </div>
                                        <FormHelperText
                                            style={{whiteSpace: "nowrap"}}>{this.state.errorMask && Config.lang("DHR_Ban_phai_nhap_dung_dinh_dang_vd_00h00m")}</FormHelperText>
                                    </FormControl>

                                ) : (
                                    <FormControl variant={"standard"}
                                                 margin={"normal"}
                                                 error={this.state.errorMask}
                                                 fullWidth={true} style={{marginTop: 27}}>
                                        <InputLabel
                                            shrink={true}
                                        >
                                            {""}
                                        </InputLabel>
                                        <div className={"display_row"}>
                                            <InputMask
                                                mask={'12:34'}
                                                maskChar={"_"}
                                                alwaysShowMask={true}
                                                formatChars={{
                                                    '1': '[0-2]',
                                                    '2': checkRuleFirstHour ? '[0-3]' : '[0-9]',
                                                    '3': '[0-5]',
                                                    '4': '[0-9]',
                                                }}
                                                onBlur={() => {
                                                    if (firstHoursLeave) {
                                                        if (!this.checkInputHoursLeave(firstHoursLeave)) {
                                                            this.setState({errorMask: true});
                                                        } else {
                                                            this.setState({errorMask: false});
                                                        }
                                                    }

                                                }}
                                                value={firstHoursLeave || ""}
                                                onChange={(e) => this.handleChange("FirstHoursLeave", e)}
                                            >
                                                {() => <TextFieldM className={classes.inputMask} variant={"standard"}
                                                                  InputProps={{
                                                                      startAdornment: (
                                                                          <IconButton>
                                                                              <Icons className={"far fa-clock"}/>
                                                                          </IconButton>
                                                                      )
                                                                  }}/>}
                                            </InputMask>

                                            <InputMask
                                                mask={'12:34'}
                                                maskChar={"_"}
                                                alwaysShowMask={true}
                                                formatChars={{
                                                    '1': '[0-2]',
                                                    '2': checkRuleLastHour ? '[0-3]' : '[0-9]',
                                                    '3': '[0-5]',
                                                    '4': '[0-9]',
                                                }}
                                                onBlur={() => {
                                                    if (lastHoursLeave) {
                                                        if (!this.checkInputHoursLeave(lastHoursLeave)) {
                                                            this.setState({errorMask: true});
                                                        } else {
                                                            this.setState({errorMask: false});
                                                        }
                                                    }

                                                }}
                                                value={lastHoursLeave || ""}
                                                onChange={(e) => this.handleChange("LastHoursLeave", e)}
                                            >
                                                {() => <TextFieldM variant={"standard"}/>}
                                            </InputMask>
                                            {/*<TextBox*/}
                                            {/*    mask={"sh:nf"}*/}
                                            {/*    maskChar={" "}*/}
                                            {/*    maskRules={{*/}
                                            {/*        's': /[0-2]/,*/}
                                            {/*        'h': checkRuleFirstHour ? /[0-3]/ :/[0-9]/,*/}
                                            {/*        'n': /[0-5]/,*/}
                                            {/*        'f': /[0-9]/*/}
                                            {/*    }}*/}
                                            {/*    value={firstHoursLeave}*/}
                                            {/*    maskInvalidMessage={""}*/}
                                            {/*    onInput={e => this.handleChange("FirstHoursLeave",e)}*/}
                                            {/*    onFocusOut={(e) => {*/}
                                            {/*        if(!e) return false;*/}
                                            {/*        if(firstHoursLeave.split('').some(val => val === " ")) {*/}
                                            {/*            this.setState({errorMask: true});*/}
                                            {/*        } else {*/}
                                            {/*            this.setState({errorMask: false},() => {*/}
                                            {/*                this.checkRegisterType(true);*/}
                                            {/*            });*/}
                                            {/*        }*/}
                                            {/*        e.component.option("isValid",true);*/}
                                            {/*    }}*/}
                                            {/*>*/}
                                            {/*</TextBox>*/}
                                            {/*<TextBox*/}
                                            {/*    mask={"sh:nf"}*/}
                                            {/*    maskChar={" "}*/}
                                            {/*    maskRules={{*/}
                                            {/*        's': /[0-2]/,*/}
                                            {/*        'h': checkRuleLastHour ? /[0-3]/ :/[0-9]/,*/}
                                            {/*        'n': /[0-5]/,*/}
                                            {/*        'f': /[0-9]/*/}
                                            {/*    }}*/}
                                            {/*    value={lastHoursLeave}*/}
                                            {/*    maskInvalidMessage={""}*/}
                                            {/*    onInput={e => this.handleChange("LastHoursLeave",e)}*/}
                                            {/*    onFocusOut={(e) => {*/}
                                            {/*        if(!e) return false;*/}
                                            {/*        if(lastHoursLeave.split('').some(val => val === " ")) {*/}
                                            {/*            this.setState({errorMask: true});*/}
                                            {/*        } else {*/}
                                            {/*            this.setState({errorMask: false},() => {*/}
                                            {/*                this.checkRegisterType(true);*/}
                                            {/*            });*/}
                                            {/*        }*/}
                                            {/*        e.component.option("isValid",true);*/}
                                            {/*    }}*/}
                                            {/*/>*/}
                                        </div>
                                        <FormHelperText>{this.state.errorMask && Config.lang("DHR_Ban_phai_nhap_dung_dinh_dang_vd_11:11")}</FormHelperText>
                                    </FormControl>
                                )}
                            </Col>}
                        </>}
                        {!isMultipleDate && IsHourLeave === 1 && <>
                            <Col xs={3}>
                                <TextField
                                    error={checkOneStDayQuan || quantity < 0}
                                    label={" "}
                                    variant={"standard"}
                                    margin={"normal"}
                                    inputProps={{
                                        readOnly: true,
                                    }}
                                    className={classes.textError}
                                    helperText={checkOneStDayQuan ? Config.lang("DHR_Khong_duoc_phep_nghi_qua_mot_ngay") :
                                        quantity < 0 ? Config.lang("DHR_So_luong_phep_khong_hop_le") : ""}
                                    value={quantity === "" ? "1" : String(quantity)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                />
                            </Col>
                        </>}
                        {!isMultipleDate && isShowColum && !IsHourLeave && <>
                            <Col xs={3}>
                                <Combo
                                    margin={"normal"}
                                    shrink={true}
                                    dataSource={dataCBoUnitLeave}
                                    showClearButton={true}
                                    disabled={dataCBoUnitLeave.length === 1}
                                    stylingMode="underlined"
                                    value={fullDateFrom}
                                    label={" "}
                                    onValueChanged={(e) => this.handleChange("fullDateFrom", e)}
                                />
                            </Col>
                        </>}

                        {isMultipleDate && <>
                            <Col xs={2}>
                                <Combo
                                    margin={"normal"}
                                    shrink={true}
                                    dataSource={dataCBoUnitLeave}
                                    showClearButton={true}
                                    disabled={dataCBoUnitLeave.length === 1}
                                    stylingMode="underlined"
                                    value={fullDateFrom}
                                    label={" "}
                                    onValueChanged={(e) => this.handleChange("fullDateFrom", e)}
                                />
                            </Col>
                            <Col xs={4}>
                                <DateBoxPicker
                                    error={error && error["LeaveDateTo"]}
                                    label={Config.lang("DHR_Den_ngay")}
                                    stylingMode={"underlined"}
                                    margin={"normal"}
                                    shrink={true}
                                    min={dateFrom ? moment(dateFrom).format("MM/DD/YYYY") : null}
                                    width={"100%"}
                                    value={dateTo ? moment(dateTo).format("MM/DD/YYYY") : null}
                                    acceptCustomValue={true}
                                    disabled={loading || isFromW75F2005 ? false : approval === 2 || !isRegister}
                                    required={true}
                                    dateBoxProps={{
                                        readOnly: disabled || isFromW75F2005,
                                    }}
                                    onValueChanged={(e) => this.handleChange("DateTo", e)}
                                />
                            </Col>
                            <Col xs={2}>
                                <Combo
                                    margin={"normal"}
                                    shrink={true}
                                    dataSource={dataCBoUnitLeave}
                                    showClearButton={true}
                                    disabled={dataCBoUnitLeave.length === 1 || isEqualDate}
                                    stylingMode="underlined"
                                    value={fullDateTo}
                                    label={" "}
                                    onValueChanged={(e) => this.handleChange("fullDateTo", e)}
                                />
                            </Col>
                        </>}
                    </Row>
                    {/*<Row>*/}
                    {/*    <Col xs={4}>*/}
                    {/*        <DateBoxPicker*/}
                    {/*            error={error && error["LeaveDateTo"]}*/}
                    {/*            label={Config.lang("DHR_Den_ngay")}*/}
                    {/*            stylingMode={"underlined"}*/}
                    {/*            margin={"normal"}*/}
                    {/*            shrink={true}*/}
                    {/*            min={dateFrom ? moment(dateFrom).format("MM/DD/YYYY") : null}*/}
                    {/*            width={"100%"}*/}
                    {/*            value={dateTo ? moment(dateTo).format("MM/DD/YYYY") : null}*/}
                    {/*            acceptCustomValue={true}*/}
                    {/*            disabled={loading || isFromW75F2005 ? false : approval === 2 || !isRegister}*/}
                    {/*            required={true}*/}
                    {/*            dateBoxProps={{*/}
                    {/*                readOnly: disabled || isFromW75F2005,*/}
                    {/*            }}*/}
                    {/*            onValueChanged={(e) => this.handleChange("DateTo", e)}*/}
                    {/*        />*/}
                    {/*    </Col>*/}
                    {/*    <Col xs={2}>*/}
                    {/*        <Combo*/}
                    {/*            margin={"normal"}*/}
                    {/*            shrink={true}*/}
                    {/*            dataSource={dataCBoUnitLeave}*/}
                    {/*            showClearButton={true}*/}
                    {/*            disabled={dataCBoUnitLeave.length === 1 || isEqualDate}*/}
                    {/*            stylingMode="underlined"*/}
                    {/*            value={fullDateTo}*/}
                    {/*            label={" "}*/}
                    {/*            onValueChanged={(e) => this.handleChange("fullDateTo", e)}*/}
                    {/*        />*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            {isMultipleDate && <TextField
                                disabled={approval === 2}
                                label={Config.lang("DHR_So_luong")}
                                variant={"standard"}
                                margin={"normal"}
                                type={"number"}
                                required={true}
                                inputProps={{
                                    step: "0.5",
                                    readOnly: true,
                                }}
                                value={String(quantity)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />}
                        </Col>
                    </Row>
                    <FormGroup>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <TextField
                                    error={error && error["Reason"]}
                                    label={Config.lang("DHR_Ly_do")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={reason}
                                    disabled={loading || isEditMode ? (Number(appStatusID) === 2 ? isRegister : false) : false}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        readOnly: Boolean(disabled) || isFromW75F2005,
                                    }}
                                    onChange={(e) => this.handleChange("Reason", e)}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <label
                                    className={"label-above-admin MuiFormLabel-root mgt15 mgb15"}
                                    style={{cursor: "pointer"}}
                                    onClick={() => {
                                        this.setState({toggleTable: !toggleTable});
                                    }}
                                >
                                    <i className={"fas fa-caret-down mgr5"}/>
                                    {Config.lang("DHR_Chi_tiet_phep")}
                                </label>
                                <AnimateHeight
                                    duration={500}
                                    height={toggleTable ? "auto" : 0} // see props documentation below
                                >
                                    <GridContainer
                                        loading={gridLoading}
                                        style={{border: "none"}}
                                        dataSource={dataDetail}
                                        showRowLines={false}
                                        showBorders={false}
                                        showColumnLines={false}
                                        noDataText={Config.lang("DHR_No_data")}
                                    >
                                        <Column dataField={"LeaveTypeID"} cellRender={this.renderItem} visible={false}/>
                                        <Column caption={Config.lang("DHR_Loai_phep")} dataField={"LeaveTypeName"}
                                                cssClass={"text-left"}/>
                                        <Column
                                            caption={Config.lang("DHR_Ngay_nghi")}
                                            dataField={"LeaveDate"}
                                            alignment={"center"}
                                            width={"100%"}
                                        />
                                        <Column caption={Config.lang("DHR_So_luong")} dataField={"DetailQty"}
                                                cssClass={"text-left"}/>
                                    </GridContainer>
                                </AnimateHeight>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <label
                                    className={"label-above-admin MuiFormLabel-root mgt15 mgb15"}
                                    style={{cursor: "pointer"}}
                                    onClick={() => {
                                        this.setState({toggleTable1: !toggleTable1});
                                    }}
                                >
                                    <i className={"fa fa-caret-down mgr5"}/>
                                    {Config.lang("DHR_Du_lieu_cham_phep")}
                                </label>
                                <AnimateHeight
                                    duration={500}
                                    height={toggleTable1 ? "auto" : 0} // see props documentation below
                                >
                                    <GridContainer
                                        totalItems={dataHistory.total}
                                        itemPerPage={this.gridHistory.limit}
                                        skipPerPage={this.gridHistory.skip}
                                        onChangePage={this.onChangePage}
                                        onChangePerPage={this.onChangePerPage}
                                        pagerFullScreen={false}
                                        style={{border: "none"}}
                                        dataSource={dataHistory.rows}
                                        showRowLines={false}
                                        showBorders={false}
                                        showColumnLines={false}
                                        typePaging={"remote"}
                                        noDataText={Config.lang("DHR_No_data")}
                                    >
                                        <Column
                                            caption={Config.lang("DHR_Ngay_phep")}
                                            dataField={"LeaveDate"}
                                            cssClass={"text-left"}
                                            cellRender={(e) => {
                                                const leaveDate = _.get(e, "data.LeaveDate", moment());
                                                return <div>{moment(leaveDate).format("DD/MM/YYYY")}</div>;
                                            }}
                                        />
                                        <Column
                                            caption={Config.lang("DHR_Loai_phep")}
                                            dataField={"LeaveTypeName"}
                                            alignment={"center"}
                                            width={"100%"}
                                        />
                                        <Column
                                            caption={Config.lang("DHR_So_luong")}
                                            // alignment={"center"}
                                            dataField={"Quantity"}
                                            cssClass={"text-left"}
                                        />
                                    </GridContainer>
                                </AnimateHeight>
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup style={{marginBottom: 34}}>
                        <Attachments
                            error={error && error["Attachment"]}
                            ref={(ref) => (this.attRef = ref)}
                            showButton={true}
                            files={dataOldAttachments}
                            disabled={disabled || !isRegister || isFromW75F2005}
                            maxLength={5}
                            uploading={loading}
                            onUploading={this.onUploading}
                            onChanged={this.onChangeAttachments}
                        />
                    </FormGroup>
                </Modal.Content>
                <Modal.Actions style={{justifyContent: "space-between"}}>
                    {!disabled && edit && (
                        <>
                            <div>
                                <IconButton aria-label="view" disabled={loading} onClick={this.onAttachment}>
                                    <AttachFileIcon/>
                                </IconButton>
                            </div>
                            <div>
                                <ButtonGeneral
                                    name={Config.lang("DHR_Luu")}
                                    typeButton={"save"}
                                    disabled={
                                        Number(data?.OnlyWatch) === 1 ||
                                        (disabledSave || loading || uploading) || gridLoading ||
                                        (Number(appStatusID) === 2 ? isRegister : Number(appStatusID) > 0 || !isRegister)
                                    }
                                    style={{textTransform: "uppercase"}}
                                    size={"large"}
                                    onClick={this.onSave}
                                />
                            </div>
                        </>
                    )}
                </Modal.Actions>
            </React.Fragment>
        );
    }
}

W75F2000Popup.propTypes = {
    open: PropTypes.bool,
    mode: PropTypes.string,
    data: PropTypes.any,
    onClose: PropTypes.func,
};

export default compose(
    connect(
        (state) => ({
            getLeaveTypes: state.W75F2000.getLeaveTypes,
            getForm: state.W75F2000.getForm,
            getAttachments: state.W75F2000.getAttachments,
            color: state.main.color,
            getRequiredFields: state.general.getRequiredFields,
            loadSetting: state.general.loadSetting,
        }),
        (dispatch) => ({
            w75F2000Actions: bindActionCreators(W75F2000Actions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        }),
    ),
    withStyles(styles),
)(W75F2000Popup);
