/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/19/2019
 * @Example
 */
import React from "react";
import {connect} from "react-redux";
import * as W75F2000Actions from "../../../../redux/W75/W75F2000/W75F2000_actions";
import {bindActionCreators, compose} from "redux";
import Config from "../../../../config";
import {Column} from 'devextreme-react/data-grid';
import {Row, Col, FormGroup} from "react-bootstrap"
import GridContainer from "../../../grid-container/grid-container";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import ButtonGeneral from "../../../common/button/button-general";
import withStyles from "@material-ui/core/styles/withStyles";
import Chip from "@material-ui/core/Chip";
import Divider from "@material-ui/core/Divider";
import Modal from "../../../common/modal/modal";
import W75F2000Popup from "./W75F2000Popup";
import * as generalActions from "../../../../redux/general/general_actions";
import _ from 'lodash';
import moment from 'moment';
import {FormLabel as Label} from "@material-ui/core";
import {Combo} from "../../../common/form-material";
import Filter from '../../../filter/filter';
import DateBoxPicker from "../../../common/form-material/date-box";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import Status from "../../../common/status/status";
import Popover from "./W75F2000Popover";
import W84F3005 from "../../../W8X/W84/W84F3005/W84F3005";
import {ButtonIcon} from "diginet-core-ui/components";

const styles = {
    divAvatar: {
        width: 46,
        height: 46,
        minWidth: 46,
        minHeight: 46,
        borderRadius: '50%',
        overflow: 'hidden',
        marginRight: 8
    },
    imgAvatar: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    chip: {
        fontSize: '1rem',
        fontWeight: 400,
        border: 'none',
        backgroundColor: '#EBEBEB'
    },
    btnFilter: {
        backgroundColor: "#9999993d",
        textTransform: "uppercase"
    },
    divider:{
        width: 2,
        height: 11,
        margin: '0 5px'
    }
};

class W75F2000 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            mode: "add",
            rowData: null,
            LeaveDateFrom: null,
            LeaveDateTo: null,
            showW75F2000Popup: false,
            showW84F3005Popup: false,
            w84F3005PopupData: {
                TransID: null
            },
            dataGrid: [],
            total: 0,
            edit: true,
            gridLoading: false,
            employeeLoading: false,
            filterLoading: false,
            EmployeeID: null,

            open: false,
            type: "",
            dataPopover: null,
            anchorEl: null,
        };
        this.filter = {
            EmployeeID: null,
            AppStatusID: "",
            IsRegisterType: "",
            LeaveTypeID: "",
            skip: 0,
            limit: 10,
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 20
        };
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W75F2000", (isPer) => {
            this.setState({iPermission: isPer});
        });
    };

    loadCboEmployees = () => {
        const params = {
            HostID: "",
            Type: "EmployeeID",
            FormID: "W75F2000",
            Language: Config.language || '84',
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch
        };
        this.setState({employeeLoading: true});
        this.props.generalActions.getCboEmployees(params, error => {
            this.setState({employeeLoading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboLeaveTypes = () => {
        const param = {
            Language: Config.language || '84'
        };
        this.props.w75F2000Actions.getLeaveTypes(param, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboRegisterTypes = () => {
        this.props.w75F2000Actions.getCboRegisterType(error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };


    loadCboAppStatus = () => {
        const param = {
            FormID: "STANDARD",
            Language: Config.language || 84
        };
        this.props.generalActions.getCboAppStatus(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };


    loadGrid = (voucher_id) => {
        const {AppStatusID, IsRegisterType, LeaveTypeID, skip, limit} = this.filter;
        const {LeaveDateFrom, LeaveDateTo, EmployeeID} = this.state;
        const { location }  = this.props;
        let params = {
            Language: Config.language || "84",
            LeaveDateFrom,
            LeaveDateTo,
            IsRegisterType,
            EmployeeID: EmployeeID && EmployeeID.EmployeeID ? EmployeeID.EmployeeID : "",
            AppStatusID,
            LeaveTypeID,
            VoucherDate: "",
            ProjectID: "",
            OrgchartID: "",
            HostID: "",
            FormID: "W75F2000",
            TransID: voucher_id || "",
            skip,
            limit,
            IsMSS: +(location?.state?.menu?.Type === "MSS")
        };
        this.setState({gridLoading: true});
        this.props.w75F2000Actions.getGrid(params, (errors, data) => {
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({gridLoading: false});
                return false;
            }
            this.setState({
                dataGrid: data && data.rows ? data.rows : [],
                total: data && data.total ? data.total : 0,
            }, () => {
                this.setState({gridLoading: false});
            });
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        Config.callChildForm({
            FormID: "W75F2000",
            // ID: voucher_id,
            keyExpr: "TransID",
            data: this.state.dataGrid,
            onLoad: (params) => this.loadGrid(params && params.ID),
            onAction: (e, data) => this.onView({row: {data: data}})
        }, this.props);
    }

    async componentDidMount() {
        await this.loadPermission();
        if (!this.state.iPermission) return;
        const {voucher_id} = Config.getUrlParams(this.props);
        this.loadCboLeaveTypes();
        if (!voucher_id) this.loadGrid();
    };

    onLoadDataFilter = () => {
        this.loadCboEmployees();
        this.loadCboAppStatus();
        this.loadCboRegisterTypes();
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    };

    onChangePerPage = (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadGrid();
    };

    onAdd = () => {
        this.setState({
            mode: "add",
            edit: true,
            rowData: null,
            showW75F2000Popup: true
        });
    };

    onCloseModal = (isSaved = false) => {
        if (isSaved) this.loadGrid();
        this.setState({
            showW75F2000Popup: false
        });
    };
    onHistoryApproval = (e, data = {}) => {
        this.setState({w84F3005PopupData: {TransID: data.TransID}, showW84F3005Popup: true})
    };
    onView = (e) => {
        const {data} = e.row;
        this.setState({
            mode: "view",
            edit: true,
            rowData: data,
            showW75F2000Popup: true
        });
    };

    onDelete = (e) => {
        const {data} = e.row;
        if (data && data.TransID) {
            Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
                let params = {
                    HostID: "",
                    FormID: "W75F2000",
                    EmployeeID: _.get(data, "EmployeeID", ""),
                    TransID: _.get(data, "TransID", "")
                };
                this.props.w75F2000Actions.deleteLeave(params, (errors) => {
                    if (errors) {
                        let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                        Config.popup.show('INFO', message);
                        return false;
                    }
                    this.loadGrid();
                    Config.notify.show('success', Config.lang("Xoa_thanh_cong"), 2000);
                });
            });
        } else {
            Config.popup.show('INFO', Config.lang("Loi_chua_xac_dinh"));
        }
    };

    onOpenAbort = (evt, e) => {
        const {data} = e.row;
        if (this.refPopover) {
            this.refPopover.onOpen(evt.currentTarget, data);
        }
    };

    filterChange = (key, data) => {
        if (!key) return false;
        const value = _.get(data, "value", "");
        // const valueEmpId = _.get(data, "value.EmployeeID", null);
        const stateFieldName = ['LeaveDateFrom', 'LeaveDateTo', 'EmployeeID'];
        if (stateFieldName.includes(key)) {
            this.setState({[key]: value});
            return;
        }
        // const val = key === "EmployeeID" ? valueEmpId : value;
        this.filter[key] = value;

    };

    renderFilters = () => {
        const {EmployeeID, employeeLoading, LeaveDateFrom, LeaveDateTo} = this.state;
        const {getLeaveTypes, getCboAppStatus, getRegisterTypes, getCboEmployees} = this.props;
        return (
            <Filter
                placeholder={Config.lang("Tim_kiem_ho_so_nhan_su")}
                onOpenLoaded={this.onLoadDataFilter}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12}>
                                        <Label>{Config.lang("Ngay_de_xuat")}</Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("Tu")}</Label>
                                            <DateBoxPicker
                                                placeholder={moment().format("DD/MM/YYYY")}
                                                max={LeaveDateTo}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                width={"100%"}
                                                value={LeaveDateFrom}
                                                onValueChanged={e => this.filterChange("LeaveDateFrom", e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("Den")}</Label>
                                            <DateBoxPicker
                                                min={LeaveDateFrom}
                                                placeholder={moment().format("DD/MM/YYYY")}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                width={"100%"}
                                                value={LeaveDateTo}
                                                onValueChanged={e => this.filterChange("LeaveDateTo", e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Combo
                                            label={Config.lang("Trang_thai_duyet")}
                                            dataSource={getCboAppStatus}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"AppStatusID"}
                                            onValueChanged={e => this.filterChange("AppStatusID", e)}
                                            showClearButton={true}
                                            value={this.filter.AppStatusID || ""}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Combo
                                            label={Config.lang("Hinh_thuc")}
                                            dataSource={getRegisterTypes}
                                            displayExpr={"RegisterType"}
                                            valueExpr={"IsRegisterType"}
                                            onValueChanged={e => this.filterChange("IsRegisterType", e)}
                                            showClearButton={true}
                                            value={this.filter.IsRegisterType || ""}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Combo
                                            label={Config.lang("Loai_phep")}
                                            dataSource={getLeaveTypes}
                                            displayExpr={"LeaveTypeName"}
                                            valueExpr={"LeaveTypeID"}
                                            onValueChanged={e => this.filterChange("LeaveTypeID", e)}
                                            showClearButton={true}
                                            value={this.filter.LeaveTypeID || ""}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Combo.LoadMore
                                            dataSource={getCboEmployees}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={'EmployeeID'}
                                            valueExpr={'EmployeeID'}
                                            value={EmployeeID}
                                            loading={employeeLoading}
                                            stylingMode={'outlined'}
                                            label={Config.lang('Nhan_vien')}
                                            itemRender={(e) => {
                                                const {data} = e;
                                                if (!data) return null;
                                                return data.EmployeeID + ' - ' + data.EmployeeName;
                                            }}
                                            showClearButton={true}
                                            onValueChanged={e => this.filterChange("EmployeeID", e)}
                                            onInputChanged={(e) => {
                                                if (this.filterCboEmployees.timer) clearTimeout(this.filterCboEmployees.timer);
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.timer = setTimeout(() => {
                                                    this.filterCboEmployees.skip = 0;
                                                    this.filterCboEmployees.limit = 20;
                                                    this.loadCboEmployees(true);
                                                }, 700);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonGeneral
                                    name={Config.lang("Tim_kiem")}
                                    typeButton={"search"}
                                    size={"large"}
                                    color={"primary"}
                                    variant={"outlined"}
                                    onClick={this.loadGrid}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    renderItem = (e) => {
        const {data} = e.row;

        const renderAmoutRegistBefore = (vl) => {
            let amout = Math.abs(vl || 0);
            if(amout > 1 && Config.getLocale() === 'en') amout+=` ${Config.lang('Ngay3')}s`;
            else amout+=` ${Config.lang('Ngay3')}`;
            return amout;
        };

        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <div className={"w75f2000_info"}>
                    <div style={{fontSize: '1.12rem'}}><UserName data={data}/></div>
                    <div style={{fontWeight: 200}}>
                        {data.Quantity} {data.LeaveTypeName} - {data.LeaveTypeID} - {data.LeaveDateFrom}{data.LeaveDateTo ? " - " + data.LeaveDateTo : ""}
                    </div>
                    {_.isNumber(data.AmoutRegistBefore) &&
                        <div style={{fontWeight: 200}}>
                            <span>
                                {_.join(
                                  [
                                    data.AmoutRegistBefore < 0 ? Config.lang("Dang_ky_sau") : Config.lang("Dang_ky_truoc_d"),
                                      renderAmoutRegistBefore(data.AmoutRegistBefore)
                                  ], ": "
                                )}
                            </span>
                        </div>
                    }
                    {data.AppStatusID > 0 &&
                    <div style={{fontWeight: 200}}>{Config.lang("Boi")} <span
                        style={{fontWeight: 500}}>{data.ApproverName}</span> - {data.DepartmentID} {data.ApprovalDate}
                    </div>
                    }
                    {data.AppStatusID > 0 &&
                    <div style={{
                        whiteSpace: "pre-wrap",
                        width: "80%"
                    }}>{Config.lang("Ghi_chu_duyet")}:{data.ApprovalNotes}</div>
                    }
                    {Boolean(data.IsRegisterType) && <>
                        {data.LeaveCancelReason &&
                        <div>{Config.lang("Ly_do_huy_phep")}: {data.LeaveCancelReason || ""}</div>}
                        <Chip label={Config.lang("Huy_phep")}
                              color="primary"
                              size="small"
                              style={{
                                  fontSize: '1rem',
                                  fontWeight: 400,
                                  borderRadius: 4
                              }}
                              variant={"default"}
                        />
                    </>}
                </div>
            </div>
        );
    };

    renderStatus = (e) => {
        const {data} = e.row;
        return (
            <Status data={data}/>
        );
    };

    renderAction = (e) => {
        let {data} = e.row;
        const {classes} = this.props;
        const {iPermission} = this.state;
        const appStatusID = _.get(data, "AppStatusID", 0);
        return (
            <div className={"display_row align-center"}>
                <ButtonIcon
                  circular={true}
                  size={"small"}
                  viewType={"text"}
                  name={"History"}
                  onClick={() => this.onHistoryApproval(e, data)}
                />
                <Divider orientation="vertical" className={classes.divider}/>

                <ButtonIcon
                  circular={true}
                  size={"small"}
                  viewType={"text"}
                  name={"View"}
                  disabled={Number(data.OnlyWatch) === 1 ? false : !iPermission >= 2}
                  onClick={() => this.onView(e)}
                />
                <Divider orientation="vertical" className={classes.divider}/>

                <ButtonIcon
                  circular={true}
                  size={"small"}
                  viewType={"text"}
                  name={"Delete"}
                  disabled={Number(data.OnlyWatch) === 1 || (Number(appStatusID) !== 0) || !(iPermission >= 4)}
                  onClick={() => this.onDelete(e)}
                />
                {
                    !data.IsRegisterType &&
                    <>
                        <Divider orientation="vertical" className={classes.divider}/>
                        <ButtonIcon
                          circular={true}
                          size={"small"}
                          viewType={"text"}
                          name={"Cancel"}
                          disabled={Number(data.OnlyWatch) === 1 || !(iPermission >= 4) || (Number(appStatusID) === 1) || !(Number(appStatusID) === 2)}
                          onClick={(evt) => this.onOpenAbort(evt, e)}
                        />
                    </>
                }
            </div>
        );
    };

    render() {
        let {
            iPermission,
            edit,
            showW75F2000Popup,
            showW84F3005Popup,
            w84F3005PopupData = {},
            dataGrid,
            mode,
            rowData,
            gridLoading,
            total,
            anchorEl,
            type,
            dataPopover
        } = this.state;
        if (!iPermission) return null;
        return (
            <React.Fragment>
                <W84F3005 open={showW84F3005Popup} onClose={e => this.setState({showW84F3005Popup: false})}
                          FormID="W75F2000" TransID={w84F3005PopupData.TransID}/>
                <Modal open={showW75F2000Popup} maxWidth={"md"} fullWidth={true}
                       title={Config.lang("Lap_nghi_phep")}
                       onClose={() => this.onCloseModal(false)}>
                    <W75F2000Popup edit={edit} mode={mode} data={rowData} onClose={this.onCloseModal}/>
                </Modal>
                <Popover
                    ref={ref => this.refPopover = ref}
                    anchorEl={anchorEl}
                    type={type}
                    data={dataPopover}
                    setLoading={isLoading => this.setState({gridLoading: isLoading})}
                    funcLoadGrid={this.loadGrid}
                />
                <div className={"hidden"}>{this.renderFilters()}</div>
                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("Nghi_phep")}
                    >
                        <ButtonGeneral name={Config.lang('Them')}
                                       disabled={gridLoading || !iPermission >= 2}
                                       typeButton={'add'}
                                       style={{textTransform: 'uppercase'}}
                                       size={"large"}
                                       onClick={this.onAdd}/>
                    </ActionToolbar>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <GridContainer
                                style={{border: 'none'}}
                                dataSource={dataGrid}
                                totalItems={total}
                                itemPerPage={this.filter.limit}
                                skipPerPage={this.filter.skip}
                                height={Config.getHeightGrid()}
                                typePaging={"remote"}
                                loading={gridLoading}
                                rowAlternationEnabled={false}
                                showRowLines={true}
                                showBorders={false}
                                showColumnLines={false}
                                showColumnHeaders={false}
                                noDataText={Config.lang("No_data")}
                                onChangePage={this.onChangePage}
                                onChangePerPage={this.onChangePerPage}
                            >
                                <Column
                                    cellRender={({row}) => <UserImage data={_.get(row, "data", {})}
                                                                      fieldImage={"UserPicture"}/>}
                                />
                                <Column
                                    dataField={'EmployeeName'}
                                    cellRender={this.renderItem}
                                    // width={300}
                                />
                                <Column
                                    dataField={'AppStatusID'}
                                    visible={false}
                                />
                                <Column
                                    alignment={"center"}
                                    dataField={'AppStatusName'}
                                    cellRender={this.renderStatus}
                                    minWidth={150}
                                    maxWidth={200}
                                />
                                <Column
                                    dataField={'AppStatusName'}
                                    cellRender={this.renderAction}
                                    width={200}
                                />
                            </GridContainer>
                        </Col>
                    </Row>
                </FormGroup>
            </React.Fragment>
        )
    }
}

export default compose(connect(state => ({
    getCboAppStatus: state.general.getCboAppStatus,
    getCboEmployees: state.general.getCboEmployees,
    getRegisterTypes: state.W75F2000.getRegisterTypes,
    getLeaveTypes: state.W75F2000.getLeaveTypes,
}), (dispatch) => ({
    generalActions: bindActionCreators(generalActions, dispatch),
    w75F2000Actions: bindActionCreators(W75F2000Actions, dispatch)
})), withStyles(styles))(W75F2000);
