/**
 * @copyright 2019 @ DigiNet
 * @author Minh Trung
 * @create 23/12/2019
 * @Example
 */

import React from "react";
import { bindActionCreators, compose } from "redux";
import { withStyles, Divider, Icon, FormLabel as Label } from "@material-ui/core";
import { FormGroup, Row, Col } from "react-bootstrap";
import Chip from "@material-ui/core/Chip";
import GridContainer from "../../../grid-container/grid-container";
import { Column } from "devextreme-react/data-grid";
import Config from "../../../../config";
import VisibilityIcon from '@material-ui/icons/Visibility';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import IconButton from "@material-ui/core/IconButton";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import PopoverW752005 from "./W75F2005Popover";
import { connect } from "react-redux";
import * as W75F2005Actions from "../../../../redux/W75/W75F2005/W75F2005_actions";
import Modal from "../../../common/modal/modal";
import { Link, browserHistory } from "react-router";
import W75F2000Popup from "../W75F2000/W75F2000Popup";
import * as generalActions from "../../../../redux/general/general_actions";
import Filter from "../../../filter/filter";
import DateBoxPicker from "../../../common/form-material/date-box";
import moment from "moment";
import { Combo } from "../../../common/form-material";
import _ from "lodash";
import Slider from "react-slick";
import FileViewer from "../../../common/fileviewer/fileviewer";
import UserImage from "../../../common/user/user-image";
import UserName from "../../../common/user/user-name";
import Status from "../../../common/status/status";
import ButtonGeneral from "../../../common/button/button-general";
import W84F3005 from "../../../W8X/W84/W84F3005/W84F3005"
import HistoryIcon from '@material-ui/icons/History';
const styles = {
    divAvatar: {
        width: 46,
        height: 46,
        minWidth: 46,
        minHeight: 46,
        borderRadius: '50%',
        overflow: 'hidden',
        marginRight: 8,
    },
    imgAvatar: {
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'contain',
    },
    chip: {
        fontSize: '1rem',
        fontWeight: 400,
        border: 'none',
        backgroundColor: '#EBEBEB',
    },
    iconHoverRed: {
        '&:hover': {
            color: '#FF405A',
        },
    },
    iconHoverGreen: {
        '&:hover': {
            color: '#1CA261',
        },
    },
    itemAtt: {
        borderRadius: 100,
        padding: '0 8px',
        border: '1px solid lightgrey',
        fontWeight: '400',
        marginRight: 10,
        fontSize: 12,
        height: 24,
        lineHeight: '22px',
        display: 'block',
        marginBottom: 10,
        '&:hover': {
            backgroundColor: '#E4E9F2'
        }
    },
    slick: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        position: 'absolute',
        top: "50%",
        zIndex: 2,
    },
};

const NextArrow = props => {
    const { onClick } = props;
    return (
        <IconButton style={{ ...styles.slick, left: "93.5%" }} size="medium" onClick={onClick} disableFocusRipple={true}>
            <KeyboardArrowRight fontSize="large" />
        </IconButton>
    );
};

const PrevArrow = props => {
    const { onClick } = props;
    return (
        <IconButton style={{ ...styles.slick, left: "2%" }} size="medium" onClick={onClick} disableFocusRipple={false}>
            <KeyboardArrowLeft fontSize="large" />
        </IconButton>
    );
};

class W75F2005 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            iPermission: 0,
            anchorEl: undefined,
            type: "",
            showW75F2005Popup: false,
            dataPopup: null,
            dataPopover: null,
            gridLoading: true,
            dataGrid: [],
            total: 0,
            LeaveDateFrom: null,
            LeaveDateTo: null,
            employeeLoading: false,
            showFileViewer: null,
            slideIndex: 0,
            EmployeeID: null,
            showW84F3005Popup: false,
            w84F3005PopupData: {
                TransID: null
            },
        };

        this.filter = {
            AppStatusID: "",
            IsRegisterType: "",
            LeaveTypeID: "",
            skip: 0,
            limit: 10,
            EmployeeID: null
        };
        this.filterCboEmployees = {
            timer: null,
            strSearch: "",
            skip: 0,
            limit: 20
        };
        this.slider = null;
        this.refPopover = null;
    }

    loadPermission = async () => {
        await this.props.generalActions.getPermission("W75F2005", (isPer) => {
            this.setState({ iPermission: isPer });
        });
    };

    loadCboLeaveTypes = () => {
        const param = {
            Language: Config.language || '84'
        };
        this.props.w75F2000Actions.getLeaveTypes(param, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboEmployees = () => {
        const params = {
            HostID: "",
            Type: "EmployeeID",
            FormID: "W75F2005",
            Language: Config.language || '84',
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch
        };
        this.setState({ employeeLoading: true });
        this.props.generalActions.getCboEmployees(params, error => {
            this.setState({ employeeLoading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    getInfo = () => {
        const { location } = this.props;
        if (location && location.state) {
            return {
                EmployeeID: location.state.EmployeeID
            }
        } else {
            return {
                EmployeeID: Config.getHREmployeeID()
            }
        }
    };

    componentDidMount = async () => {
        await this.loadPermission();
        if (!this.state.iPermission) return;
        this.loadGrid();

    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        Config.callChildForm({
            FormID: "W75F2005",
            // ID: voucher_id,
            keyExpr: "TransID",
            data: this.state.dataGrid,
            onLoad: (params) => this.loadGrid(params && params.ID),
            onAction: (e, data) => this.onView(null, data)
        }, this.props);
    }

    loadGrid = (VoucherID) => {
        const {voucher_id} = Config.getUrlParams(this.props);
        VoucherID = VoucherID ? VoucherID : (voucher_id ? voucher_id : "");
        const { AppStatusID, skip, limit } = this.filter;
        const { LeaveDateFrom, LeaveDateTo, EmployeeID } = this.state;
        let params = {
            Language: Config.language || "84",
            LeaveDateFrom,
            LeaveDateTo,
            EmployeeID: EmployeeID && EmployeeID.EmployeeID ? EmployeeID.EmployeeID : "",
            AppStatusID,
            VoucherDate: "",
            ProjectID: "",
            OrgchartID: "",
            HostID: "",
            FormID: "W75F2005",
            TransID: VoucherID || "",
            skip,
            limit
        };
        this.setState({ gridLoading: true });
        this.props.W75F2005Actions.loadGrid(params, (errors, data) => {
            if (errors) {
                let message = errors.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                this.setState({ gridLoading: false });
                return false;
            }
            this.setState({
                dataGrid: data && data.rows ? data.rows : [],
                total: data && data.total ? data.total : 0,
            }, () => {
                this.setState({ gridLoading: false });
            });
        });
    };

    empProfile = (data) => {
        const EmployeeID = data.EmployeeID;

        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: { EmployeeID: EmployeeID }
        })
    };

    renderItem = (e) => {
        const { classes } = this.props;
        const { data } = e.row;

        const renderAmoutRegistBefore = (vl) => {
            let amout = Math.abs(vl || 0);
            if(amout > 1 && Config.getLocale() === 'en') amout+=` ${Config.lang('Ngay3')}s`;
            else amout+=` ${Config.lang('Ngay3')}`;
            return amout;
        };

        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <div className={"w75f2000_info"}>
                    <Link style={{ fontSize: '1.12rem', fontWeight: 500, color: "#707070" }} onClick={() => this.empProfile(data)} onlyActiveOnIndex><UserName data={data} renderItem={() => (data.EmployeeID + " - " + data.EmployeeName)} /></Link>
                    <div style={{ fontWeight: 200 }}>
                        {data.Quantity} {data.LeaveTypeName} - {data.LeaveTypeID} - {data.LeaveDateFrom}{data.LeaveDateTo ? " - " + data.LeaveDateTo : ""}
                    </div>
                    {_.isNumber(data.AmoutRegistBefore) &&
                        <div style={{fontWeight: 200}}>
                            <span>
                                {_.join(
                                  [
                                      data.AmoutRegistBefore < 0 ? Config.lang("Dang_ky_sau") : Config.lang("Dang_ky_truoc_d"),
                                      renderAmoutRegistBefore(data.AmoutRegistBefore)
                                  ], ": "
                                )}
                            </span>
                        </div>
                    }
                    {(data.AppStatusID !== '0' && data.AppStatusID !== 0) &&
                        <div style={{ fontWeight: 200, whiteSpace:"pre-wrap", width: "80%" }}>{Config.lang("Ghi_chu")}:
                            <span> {data.Notes}</span>
                        </div>
                    }
                    {data.arrAttachment &&
                        <div style={{ marginTop: 10, marginBottom: 10, display: 'flex', flexWrap: 'wrap' }}>
                            {data.arrAttachment.map((item, idx) => {
                                if (idx > 2) return null;
                                return (
                                    <span onClick={() => { this.setState({ showFileViewer: data, slideIndex: idx }) }} key={idx} className={classes.itemAtt}>{item.FileName}</span>
                                )
                            })}
                            {data.arrAttachment.length > 3 &&
                                <span
                                    onClick={() => { this.setState({ showFileViewer: data, slideIndex: data.arrAttachment.length - 2 }) }}
                                    className={classes.itemAtt}
                                    style={{ display: 'inline-flex', textAlign: 'center', width: "auto", padding: 5, justifyContent: "center", alignItems: "center" }}>
                                    +{parseInt(data.arrAttachment.length) - 3}
                                </span>
                            }
                        </div>
                    }
                    {Boolean(data.IsRegisterType) && <>
                        {data.LeaveCancelReason &&
                            <div>{Config.lang("Ly_do_huy_phep")}: {data.LeaveCancelReason || ""}</div>}
                        <Chip label={Config.lang("Huy_phep")}
                            color="primary"
                            size="small"
                            style={{
                                fontSize: '1rem',
                                fontWeight: 400,
                                borderRadius: 4
                            }}
                            variant={"default"}
                        />
                    </>}
                </div>
            </div>
        );
    };

    renderStatus = (e) => {
        const { data } = e.row;
        return <Status data={data} displayExpr={"AppStatus"} />;
    };
    onHistoryApproval = (e, data = {}) => {
        this.setState({ w84F3005PopupData: { TransID: data.TransID }, showW84F3005Popup: true })
    };
    onOpenCheck = (e, data) => {
        if (data.IsCancelApproval === true) {
            Config.popup.show("INFO", Config.lang("Phieu_dang_ky_da_duoc_duyet_ban_khong_duoc_phep_thay_doi"))
        }
        else {
            this.setState({
                anchorEl: e.currentTarget,
                type: "check",
                dataPopover: data
            }, () => {
                if (this.refPopover) {
                    this.refPopover.onCheck()
                }
            });
        }
    };

    onOpenClose = (e, data) => {
        if (data.IsCancelApproval === true) {
            Config.popup.show("INFO", Config.lang("Phieu_dang_ky_da_duoc_duyet_ban_khong_duoc_phep_thay_doi"))
        }
        else {
            this.setState({
                anchorEl: e.currentTarget,
                type: "close",
                dataPopover: data
            }, () => {
                if (this.refPopover) {
                    this.refPopover.onCheck()
                }
            });
        }
    };

    onView = (e, data) => {
        this.setState({
            showW75F2005Popup: true,
            dataPopup: data
        })
    };

    onCloseModal = () => {
        this.setState({
            showW75F2005Popup: false,
        })
    };

    renderAction = (e) => {
        const { classes } = this.props;
        const { data } = e.row;
        return (
            <div className={"display_row align-center"}>
                <IconButton aria-label="view" size="small" onClick={() => this.onHistoryApproval(e, data)}>
                    <HistoryIcon />
                </IconButton>
                <Divider orientation="vertical" style={{ width: 2, height: 11, margin: '0 5px' }} />
                <IconButton aria-label="view" size="small"
                    className={classes.iconHoverGreen}
                    onClick={(e) => this.onOpenCheck(e, data)}>
                    <Icon className="fas fa-check" fontSize={"small"} />
                </IconButton>
                <Divider
                    orientation="vertical" style={{ width: 2, height: 11, margin: '0 5px' }}
                />
                <IconButton aria-label="view" size="small" className={classes.iconHoverRed}
                    onClick={(e) => this.onOpenClose(e, data)}>
                    <Icon className="fas fa-times" fontSize={"small"} />
                </IconButton>
                <Divider
                    orientation="vertical" style={{ width: 2, height: 11, margin: '0 5px' }}
                />
                <IconButton aria-label="view" size="small" onClick={(e) => this.onView(e, data)}>
                    <VisibilityIcon fontSize="small" />
                </IconButton>
            </div>
        )
    };

    filterChange = (key, data) => {
        if (!key) return false;
        const value = _.get(data, "value", "");
        // const valueEmpId = _.get(data, "value.EmployeeID", null);
        const stateFieldName = ['LeaveDateFrom', 'LeaveDateTo', 'EmployeeID'];
        if (stateFieldName.includes(key)) {
            // const val = key === "EmployeeID" ? valueEmpId : value;
            this.setState({ [key]: value });
        } else {
            this.filter[key] = value;
        }
    };

    onLoadDataFilter = () => {
        this.loadCboEmployees();
        this.loadCboAppStatus();
    };

    loadCboAppStatus = () => {
        const param = {
            FormID: "W75F2005",
            Language: Config.language || 84
        };
        this.props.generalActions.getCboAppStatus(param, error => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    renderFilters = () => {
        const { EmployeeID, employeeLoading, LeaveDateFrom, LeaveDateTo } = this.state;
        const { getCboAppStatus, getCboEmployees } = this.props;
        return (
            <Filter
                onTextChanged={this.onSearch}
                placeholder={Config.lang("Tim_kiem_ho_so_nhan_su")}
                onOpenLoaded={this.onLoadDataFilter}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12}>
                                        <Label>{Config.lang("Ngay_de_xuat")}</Label>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("Tu")}</Label>
                                            <DateBoxPicker
                                                placeholder={moment().format("DD/MM/YYYY")}
                                                max={LeaveDateTo}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                width={"100%"}
                                                value={LeaveDateFrom}
                                                onValueChanged={e => this.filterChange("LeaveDateFrom", e)}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={6}>
                                        <div className={"display_row align-center"}>
                                            <Label className={"mgr5"}>{Config.lang("Den")}</Label>
                                            <DateBoxPicker
                                                min={LeaveDateFrom}
                                                placeholder={moment().format("DD/MM/YYYY")}
                                                useMaskBehavior={true}
                                                showClearButton={true}
                                                width={"100%"}
                                                value={LeaveDateTo}
                                                onValueChanged={e => this.filterChange("LeaveDateTo", e)}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Combo
                                            label={Config.lang("Trang_thai_duyet")}
                                            dataSource={getCboAppStatus}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"AppStatusID"}
                                            onValueChanged={e => this.filterChange("AppStatusID", e)}
                                            showClearButton={true}
                                            value={this.filter.AppStatusID || ""}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} >
                                        <Combo.LoadMore
                                            dataSource={getCboEmployees}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            displayExpr={"EmployeeName"}
                                            keyExpr={'EmployeeID'}
                                            valueExpr={'EmployeeID'}
                                            value={EmployeeID}
                                            loading={employeeLoading}
                                            stylingMode={'outlined'}
                                            label={Config.lang('Nhan_vien')}
                                            itemRender={(e) => {
                                                const { data } = e;
                                                if (!data) return null;
                                                return data.EmployeeID + ' - ' + data.EmployeeName;
                                            }}
                                            showClearButton={true}
                                            // showCheckBoxMode={"none"}
                                            onValueChanged={e => this.filterChange("EmployeeID", e)}
                                            onInputChanged={(e) => {
                                                if (this.filterCboEmployees.timer) clearTimeout(this.filterCboEmployees.timer);
                                                this.filterCboEmployees.strSearch = e.target.value;
                                                this.filterCboEmployees.timer = setTimeout(() => {
                                                    this.filterCboEmployees.skip = 0;
                                                    this.filterCboEmployees.limit = 20;
                                                    this.loadCboEmployees(true);
                                                }, 700);
                                            }}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <ButtonGeneral
                                    name={Config.lang("Tim_kiem")}
                                    typeButton={"search"}
                                    size={"large"}
                                    color={"primary"}
                                    variant={"outlined"}
                                    onClick={this.loadGrid}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    onChangePage = (page) => {
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    };

    onChangePerPage = (perPage) => {
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadGrid();
    };

    render() {
        const { iPermission, anchorEl, type, showW75F2005Popup, dataPopup, dataPopover, gridLoading, dataGrid, total, showFileViewer,
            showW84F3005Popup, w84F3005PopupData = {} } = this.state;
        if (!iPermission) return null;
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            lazyLoad: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            nextArrow: <NextArrow />,
            prevArrow: <PrevArrow />
        };
        return (
            <React.Fragment>
                <W84F3005 open={showW84F3005Popup} onClose={e => this.setState({ showW84F3005Popup: false })} FormID="W75F2005" TransID={w84F3005PopupData.TransID} />
                <Modal open={showW75F2005Popup} maxWidth={"md"} fullWidth={true}
                    onClose={this.onCloseModal}
                    component={
                        <W75F2000Popup fromW75F2005 mode={"view"} data={dataPopup} />
                    }
                />
                <PopoverW752005
                    ref={ref => this.refPopover = ref}
                    anchorEl={anchorEl}
                    type={type}
                    data={dataPopover}
                    setLoading={isLoading => this.setState({ gridLoading: isLoading })}
                    funcLoadGrid={this.loadGrid}
                />
                <div className={"hidden"}>{this.renderFilters()}</div>
                <FormGroup>
                    <ActionToolbar
                        title={Config.lang("Duyet_nghi_phep")}
                    />
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <GridContainer
                                style={{ border: 'none' }}
                                dataSource={dataGrid}
                                totalItems={total}
                                itemPerPage={this.filter.limit}
                                skipPerPage={this.filter.skip}
                                height={Config.getHeightGrid()}
                                typePaging={"remote"}
                                loading={gridLoading}
                                showRowLines={false}
                                showBorders={false}
                                showColumnLines={false}
                                showColumnHeaders={false}
                                noDataText={Config.lang("No_data")}
                                onChangePage={this.onChangePage}
                                onChangePerPage={this.onChangePerPage}
                            >
                                <Column
                                    cellRender={({row}) =>   <UserImage data={_.get(row,"data", {})} fieldImage={"UserPicture"} />}
                                />
                                <Column
                                    dataField={"EmployeeName"}
                                    cellRender={this.renderItem}
                                />
                                <Column
                                    alignment={"center"}
                                    dataField={'AppStatus'}
                                    cellRender={this.renderStatus}
                                    minWidth={150}
                                    maxWidth={200}
                                />
                                <Column
                                    cellRender={this.renderAction}
                                />
                            </GridContainer>
                        </Col>
                    </Row>
                    {showFileViewer && <Modal className={"modal-review-file"} style={{ zIndex: 1504 }}
                        open={!!showFileViewer}
                        maxWidth={"lg"}
                        fullWidth={true}
                        paperProps={{ style: { height: '100%' } }}
                        title={Config.lang('Xem_tap_tin')}
                        onClose={() => { this.setState({ showFileViewer: null }) }}>
                        <Slider initialSlide={this.state.slideIndex} {...settings}>
                            {showFileViewer.arrAttachment.map((file, idx) => {
                                const FileName = file && file.FileName ? file.FileName : "";
                                const FileExt = file && file.fileExt ? file.fileExt : Config.helpers.getExtFile(file ? file.FileName : '');
                                const URL = file && file.URL ? (file.URL.indexOf('http') < 0 ? Config.getCDNPath() + file.URL : file.URL) : "";
                                return (
                                    <FileViewer
                                        key={idx}
                                        fileName={FileName}
                                        filePath={URL}
                                        fileType={FileExt}
                                        height={790} />
                                )
                            })}
                        </Slider>
                    </Modal>}
                </FormGroup>
            </React.Fragment >
        );
    }
}

export default compose(connect((state) => ({
    getCboAppStatus: state.general.getCboAppStatus,
    getCboEmployees: state.general.getCboEmployees,
    loadGrid: state.W75F2005.loadGrid
}),
    (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
        W75F2005Actions: bindActionCreators(W75F2005Actions, dispatch),
    })
), withStyles(styles, { withTheme: true }))(W75F2005);
