/**
 * @copyright 2020 @ DigiNet
 * @author THIEN
 * @create 9/1/2019
 * @Example
 */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import * as W75F2010Actions from "../../../../redux/W75/W75F2010/W75F2010_actions";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import { Row, Col, FormGroup } from "react-bootstrap";
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField/TextField';
import ButtonGeneral from "../../../common/button/button-general";
import withStyles from "@material-ui/core/styles/withStyles";
import { Combo, MForm } from "../../../common/form-material";
import { TextField as TextFieldCustom } from "../../../common/form-material";
import * as generalActions from "../../../../redux/general/general_actions";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Attachments from "../../../common/attachments/attachments";
import CDN from "../../../CDN";
import Modal from "../../../common/modal/modal";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Icons from "../../../common/icons/";
import _ from "lodash";
const styles = {
    avatar: {
        width: 30,
        height: 30,
        borderRadius: "50%",
        border: "1px solid #ddd",
        overflow: "hidden",
        marginRight: 8
    },
    imgAvatar: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain"
    },
    chip: {
        fontSize: "1rem",
        fontWeight: 200,
        border: "none",
        backgroundColor: "#EBEBEB"
    },
    iconButton: {
        width: 30,
        height: 30,
        position: "absolute",
        left: "1rem",
        top: 0
    },
    comboSpec: {
        margin: '0 0 10px',
        '& .dx-selectbox.dx-textbox': {
            paddingLeft: 30
        },
    },
    numberInput: {
        width: '100%',
        marginTop: '4px'
    }
};

class W75F2010Popup extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            absentVoucherID: "",
            voucherStatus: false,
            employee: null,
            project: null,
            note: "",
            PeriodTime: "",
            absentTypeRow: [
                {
                    TransID: "",
                    AbsentTypeID: "",
                    AbsentTypeName: "",
                    Number: ""
                }
            ],
            dataAbsentType: [],
            dataOldAttachments: [],
            dataCboProjects: {
                rows: [],
                total: 0
            },
            disabled: false,
            error: {},
            loading: false,
            loadingAttachments: false,
            loadingProjects: false,
            uploading: false,
        };
        this.attachments = [];
        this.deletedFile = [];
        this.filterCboProjects = {
            strSearch: "",
            skip: 0,
            limit: 50
        };
    }

    loadCboProjects = (isReset) => {
        const param = {
            HostID: "",
            FormID: "W75F2010",
            Language: Config.language || "84",
            skip: this.filterCboProjects.skip,
            limit: this.filterCboProjects.limit,
            search: this.filterCboProjects.strSearch
        };
        this.setState({ loading: true, loadingProjects: true });
        this.props.generalActions.getCboProjects(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.setState({ loading: false, loadingProjects: false });
            if (data) {
                const { dataCboProjects } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboProjects: {
                        rows: isReset ? rows : dataCboProjects.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };

    loadCboAbsentType = () => {
        this.setState({ loading: true });
        this.props.W75F2010Actions.loadAbsentType((error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            this.setState({ loading: false, dataAbsentType: data });
        });
    };

    loadCboPeriods = () => {
        const param = {
            DivisionID: Config.getDivisionID()
        };
        this.setState({ loading: true });
        this.props.W75F2010Actions.loadPeriod(param, (error) => {
            this.setState({ loading: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadForm = () => {
        const { data } = this.props;
        const param = {
            DivisionID: Config.getDivisionID(),
            VoucherID: data && data.AbsentVoucherID ? data.AbsentVoucherID : "",
            FormID: "W13F1000",
            Language: Config.language || "84"
        };

        this.setState({ loading: true });
        this.props.W75F2010Actions.getForm(param, (error, data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const user = Config.getUser({ EmployeeID: data.EmployeeID });
                this.setState({
                    employee: user ? user : data,
                    absentVoucherID: data.AbsentVoucherID,
                    absentTypeRow: data.AbsentType,
                    PeriodTime: data.Period,
                    note: data.Note,
                    project: data
                }, () => {
                    this.loadCboPeriods();
                });
            }

            this.setState({ loading: false });
        });
    };

    loadAttachments = () => {
        const { data } = this.props;
        const param = {
            KeyID: data && data.AbsentVoucherID ? data.AbsentVoucherID : "",
        };
        this.setState({ loadingAttachments: true });
        this.props.generalActions.getAttachmentsByTransID(param, (error, data) => {
            this.setState({ loadingAttachments: false });
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data && data.length > 0) {
                this.setState({
                    dataOldAttachments: data
                });
            }
        });
    };

    handleChange = (key, e, i) => {
        if (!key) return false;
        switch (key) {
            case "AbsentType": {
                const { getCboAbsentType } = this.props;
                let { absentTypeRow } = this.state;
                absentTypeRow[i] = {
                    ...absentTypeRow[i],
                    AbsentTypeID: e.selectedItem.AbsentTypeID,
                    AbsentTypeName: e.selectedItem.AbsentTypeName
                };
                const listSelected = absentTypeRow.map(m => {
                    return m.AbsentTypeID;
                });
                this.setState({
                    absentTypeRow: absentTypeRow,
                    dataAbsentType: getCboAbsentType.filter(data => {
                        return listSelected.indexOf(data.AbsentTypeID) === -1;
                    })
                });
                break;
            }
            case "Number": {
                this.setState({
                    absentTypeRow: [
                        ...this.state.absentTypeRow.slice(0, i),
                        { ...this.state.absentTypeRow[i], Number: e },
                        ...this.state.absentTypeRow.slice(i + 1)
                    ]
                });
                break;
            }
            case "EmployeeID": {
                this.setState({
                    employee: e.value
                });
                break;
            }
            case "PeriodTime": {
                this.setState({
                    PeriodTime: e.value
                })
                break;
            }
            case "ProjectID": {
                this.setState({
                    project: e.value
                });
                break;
            }
            case "Note": {
                this.setState({
                    note: e.target.value
                });
                break;
            }
            default:
                break;
        }
    };

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    // onChangeAttachments = (e) => {
    //   this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
    //   if (e.deletedFiles && e.deletedFiles.length > 0) {
    //     this.deletedFile.push(e.deletedFiles[0]);
    //     const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
    //     this.setState(prevState => ({
    //       dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
    //         return _arrRemove.indexOf(att.AttachmentID) < 0;
    //       })
    //     }));
    //   }
    // };

    onChangeAttachments = (e) => {
        this.attachments = e.uploadedFiles ? e.uploadedFiles : [];
        if (e.deletedFiles && e.deletedFiles.length > 0) {
            this.deletedFile = [...e.deletedFiles];
            const _arrRemove = this.deletedFile.map((d) => d.AttachmentID);
            this.setState(prevState => ({
                dataOldAttachments: prevState.dataOldAttachments.filter((att) => {
                    return _arrRemove.indexOf(att.AttachmentID) < 0;
                })
            }));
        }
    };

    onUploading = (value) => {
        this.setState({ uploading: value });
    };

    setStateErrorText(objValue, key) {
        const { errorHelperText } = this.state;
        const stateError = key ? key : "error";
        this.setState({
            [stateError]: {
                ...errorHelperText,
                ...objValue
            }
        });
        return Object.keys(objValue).length !== 0;
    }

    onSave = async () => {
        const { mode, getCboPeriod } = this.props;
        const {
            employee,
            note,
            PeriodTime,
            project,
            absentTypeRow,
            absentVoucherID
        } = this.state;
        const arrAttachments = this._getAttachments();
        const getPeriodItem = _.filter(getCboPeriod, item => _.get(item, "PeriodTime", "") === PeriodTime);
        const param = {
            AbsentVoucherID: absentVoucherID ? absentVoucherID : "",
            DivisionID: _.get(getPeriodItem, "[0]DivisionID", ""),
            EmployeeID:_.get(employee,"EmployeeID",""),
            ProjectID: _.get(project,"ProjectID",""),
            TranMonth: _.get(getPeriodItem,"[0]TranMonth",""),
            TranYear: _.get(getPeriodItem,"[0]TranYear" ,""),
            ModuleID: "D13",
            HostID: "",
            Note: note,
            NoteU: note,
            TransTypeID: "W75F2010",
            data: JSON.stringify([
                ...absentTypeRow.map((item) => ({
                    AbsentTypeID: Object.keys(item).length > 0 ? item.AbsentTypeID : "",
                    NumberOfDays: Object.keys(item).length > 0 ? item.Number : ""
                }))
            ]),
            arrAttachment: JSON.stringify(arrAttachments)
        };

        const validateRules = [
            {
                name: "EmployeeID",
                rules: "isRequired",
                value: employee && employee.EmployeeID ? employee.EmployeeID : ""
            },
            {
                name: "PeriodTime",
                rules: "isRequired",
                value: _.size(getPeriodItem) > 0 ? getPeriodItem : null
            }
        ];
        const validateForm = MForm.formValidation(validateRules);
        if (this.setStateErrorText(validateForm)) return false;
        this.setState({ loading: true })
        this.props.W75F2010Actions[mode === "edit" ? "editAbsent" : "saveAbsent"](param, async (error, data) => {
            if (error) {
                let message = Config.lang("DHR_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                this.setState({ loading: false });
                return false;
            }
            if (data) {
                if (data && data.Status === 1) {
                    Config.popup.show("INFO", data.Message);
                    this.setState({ loading: false });
                    return false;
                } else {
                    this._removeCDN();
                    Config.notify.show(
                        "success",
                        Config.lang("DHR_Luu_thanh_cong"),
                        2000
                    );
                    this.onClose(true);
                }
            }
            this.setState({ loading: false });
        }
        );
    };

    _removeCDN = () => {
        if (this.deletedFile && this.deletedFile.length > 0) {
            this.deletedFile.forEach((e) => {
                const path = e.URL.split("=");
                if (path && path.length > 1) {
                    const params = {
                        path: path[1]
                    };
                    CDN.removeFile(params);
                }
            });
            this.deletedFile = [];
        }
    };

    _getAttachments = () => {
        const listAttachments = Config.helpers.getFileInfomations(this.attachments);
        const { dataOldAttachments } = this.state;

        let arrAttachment = [];
        listAttachments.forEach((att) => {
            arrAttachment.push({
                URL: att.url ? att.url : "",
                FileName: att.fileName ? att.fileName : "",
                FileSize: att.fileSize ? att.fileSize : "",
                FileExt: att.fileExt ? att.fileExt : ""
            });
        });
        if (dataOldAttachments && dataOldAttachments.length > 0) {
            arrAttachment = dataOldAttachments.concat(arrAttachment);
        }

        return arrAttachment;
    };

    resetForm = () => {
        this.setState({
            absentVoucherID: "",
            employee: null,
            project: null,
            note: "",
            periodItem: null,
            absentTypeRow: [
                {
                    TransID: "",
                    AbsentTypeID: "",
                    AbsentTypeName: "",
                    Number: ""
                }
            ],
            error: {}
        });
    };

    onClose = (isReload = false) => {
        const { onClose } = this.props;
        this.resetForm();
        if (onClose) onClose(isReload);
    };

    onAddRow = () => {
        this.setState({
            absentTypeRow: [
                ...this.state.absentTypeRow,
                {
                    TransID: "",
                    AbsentTypeID: "",
                    AbsentTypeName: "",
                    Number: ""
                }
            ]
        });
    };

    onDeleteRow = i => {
        const { getCboAbsentType } = this.props;
        let { absentTypeRow } = this.state;
        absentTypeRow = absentTypeRow.filter((_, j) => j !== i);
        const listSelected = absentTypeRow.map(m => {
            return m.AbsentTypeID;
        });
        this.setState({
            absentTypeRow: absentTypeRow,
            dataAbsentType: getCboAbsentType.filter(data => {
                return listSelected.indexOf(data.AbsentTypeID) === -1;
            })
        });
    };

    componentDidMount() {
        const { mode, data } = this.props;
        this.loadCboProjects();
        this.loadCboAbsentType();
        if (mode !== "add") {
            this.loadForm();
            this.loadAttachments();
            this.setState({
                disabled: mode === "view"
            });
            if (data) {
                if (Number(data.AppStatusID) !== 0) this.setState({ disabled: true });
                if (data.VoucherStatus === null || data.VoucherStatus === 1) {
                    this.setState({ voucherStatus: false });
                } else {
                    this.setState({ voucherStatus: true });
                }
            }
        } else {
            this.loadCboPeriods();
        }
    }

    numberFormat(number) {
        const formatter = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        let numberFormatted = formatter.format(String(number));
        let removeComma = numberFormatted.replace(/,/gi, '');
        const dataNumber = removeComma.indexOf('.00');
        if (dataNumber !== -1) {
            removeComma = removeComma.slice(0, dataNumber);
        }
        return removeComma;
    }

    renderAbsentType = () => {
        const { classes , mode} = this.props;
        const { dataAbsentType, absentTypeRow, error, loading } = this.state;

        return (
            absentTypeRow &&
            absentTypeRow.map((item, i) => {
                let dataTemp = [...dataAbsentType];
                if (item && item.AbsentTypeID) {
                    dataTemp.unshift(item);
                }

                return (
                    <Row key={i}>
                        <Col xs={8} sm={8} md={8} lg={8}>
                            <Combo
                                className={classes.comboSpec}
                                error={error && error["AbsentTypeID"]}
                                dataSource={Config.storeDataSoureDevExtreme(dataTemp)}
                                displayExpr={item =>
                                    item && `${item.AbsentTypeID} - ${item.AbsentTypeName}`
                                }
                                valueExpr={"AbsentTypeID"}
                                stylingMode={"underlined"}
                                disabled={loading || mode === "view"}
                                margin={"dense"}
                                value={item.AbsentTypeID}
                                shrink={true}
                                onSelectionChanged={e => this.handleChange("AbsentType", e, i)}
                            />
                            <IconButton
                                disabled={absentTypeRow.length < 1}
                                className={classes.iconButton}
                                aria-label="delete"
                                size="small"
                                onClick={() => this.onDeleteRow(i, item.AbsentTypeID)}
                            >
                                <Icons name={"delete"}/>
                            </IconButton>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4}>
                            <NumberFormat
                                disabled={mode === "view"}
                                className={classes.numberInput}
                                fullWidth
                                customInput={TextField}
                                thousandSeparator={true}
                                isNumericString={true}
                                value={String(item.Number) || ""}
                                onValueChange={e => this.handleChange("Number", this.numberFormat(e.floatValue), i)}
                            />
                        </Col>
                    </Row>
                );
            })
        );
    };

    render() {
        const { getCboPeriod, mode } = this.props;
        const {
            uploading,
            employee,
            project,
            error,
            disabled,
            loading,
            loadingAttachments,
            note,
            absentTypeRow,
            voucherStatus,
            dataOldAttachments,
            dataCboProjects,
            loadingProjects,
            PeriodTime
        } = this.state;
        return (
            <React.Fragment>
                <Modal.Content>
                    <FormGroup>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <Combo.Employees
                                    acceptCustomValue={true}
                                    error={error && error["EmployeeID"]}
                                    displayExpr={'EmployeeName'}
                                    keyExpr={'EmployeeID'}
                                    valueExpr={'EmployeeID'}
                                    subTextExpr={"EmployeeID"}
                                    value={employee}
                                    FormID={"W75F2010"}
                                    stylingMode={'underlined'}
                                    shrink={true}
                                    required={true}
                                    disabled={loading}
                                    label={Config.lang('DHR_Nhan_vien')}
                                    dropdownProps={{
                                        readOnly: disabled
                                    }}
                                    onValueChanged={e => this.handleChange("EmployeeID", e)}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col xs={8} sm={8} md={8} lg={8}>
                                <Combo.LoadMore
                                    dataSource={dataCboProjects}
                                    skip={this.filterCboProjects.skip}
                                    limit={this.filterCboProjects.limit}
                                    displayExpr={'ProjectName'}
                                    keyExpr={'ProjectID'}
                                    valueExpr={'ProjectID'}
                                    value={project}
                                    loading={loadingProjects}
                                    stylingMode={'underlined'}
                                    shrink={true}
                                    disabled={loading || loadingProjects}
                                    margin={"normal"}
                                    dropdownProps={{
                                        readOnly: disabled
                                    }}
                                    label={Config.lang('DHR_Du_an')}
                                    itemRender={(e) => {
                                        const { data } = e;
                                        if (!data) return null;
                                        return data.ProjectID + ' - ' + data.ProjectName;
                                    }}
                                    // showCheckBoxMode={"none"}
                                    onValueChanged={e => this.handleChange("ProjectID", e)}
                                    onInputChanged={(e) => {
                                        this.filterCboProjects.strSearch = e.target.value;
                                        this.filterCboProjects.skip = 0;
                                        this.loadCboProjects(true);
                                    }}
                                    onLoadMore={(e) => {
                                        this.filterCboProjects.skip = e.skip;
                                        this.filterCboProjects.limit = e.limit;
                                        this.loadCboProjects();
                                    }}
                                />
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4}>
                                <Combo
                                    error={error && error["PeriodTime"]}
                                    dataSource={getCboPeriod}
                                    displayExpr={"PeriodTime"}
                                    valueExpr={"PeriodTime"}
                                    stylingMode={"underlined"}
                                    margin={"normal"}
                                    disabled={loading}
                                    selectProps={{
                                        readOnly: disabled
                                    }}
                                    value={PeriodTime}
                                    shrink={true}
                                    required={true}
                                    label={Config.lang("DHR_Ky_luong")}
                                    onValueChanged={(e) => this.handleChange("PeriodTime", e)}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8} sm={8} md={8} lg={8} style={{ paddingTop: 8 }}>
                                <label className={"MuiFormLabel-root"}>
                                    {Config.lang("DHR_Loai_thu_nhap")}
                                </label>
                                <IconButton
                                    color="primary"
                                    className={"btn-add-w75f2010Popup"}
                                    style={{
                                        width: 30,
                                        height: 30
                                    }}
                                    aria-label="add"
                                    size="small"
                                    onClick={() => this.onAddRow()}
                                >
                                    <AddIcon fontSize="small" />
                                </IconButton>
                            </Col>
                            <Col xs={4} sm={4} md={4} lg={4} style={{ paddingTop: 15 }}>
                                <label className={"MuiFormLabel-root"}>
                                    {Config.lang("DHR_So_luong")}
                                </label>
                            </Col>
                        </Row>
                        {this.renderAbsentType()}
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <TextFieldCustom
                                    label={Config.lang("DHR_Ghi_chu")}
                                    variant={"standard"}
                                    margin={"normal"}
                                    value={note || ""}
                                    disabled={loading}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        readOnly: disabled
                                    }}
                                    onChange={e => this.handleChange("Note", e)}
                                    fullWidth
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup style={{ marginBottom: 34 }}>
                        <Attachments
                            ref={ref => this.attRef = ref}
                            disabled={disabled || loadingAttachments}
                            maxLength={5}
                            files={dataOldAttachments}
                            uploading={loadingAttachments}
                            onUploading={this.onUploading}
                            onChanged={this.onChangeAttachments}
                        />
                    </FormGroup>
                </Modal.Content>
                {mode !== "view" ? <Modal.Actions style={{justifyContent: "space-between"}}>
                    <div>
                        <IconButton aria-label="view" disabled={loading} onClick={this.onAttachment}>
                            <AttachFileIcon/>
                        </IconButton>
                    </div>
                    {mode !== "view" && <div>
                        <ButtonGeneral
                            name={Config.lang("DHR_Luu")}
                            typeButton={"save"}
                            disabled={
                                loading || absentTypeRow.length < 1 || voucherStatus || uploading
                            }
                            style={{textTransform: "uppercase"}}
                            size={"large"}
                            onClick={this.onSave}
                        />
                    </div>}
                </Modal.Actions> : ""}
            </React.Fragment>
        );
    }
}

W75F2010Popup.propTypes = {
    mode: PropTypes.string,
    data: PropTypes.any,
    onClose: PropTypes.func
};

export default compose(
    connect(
        state => ({
            getCboProjects: state.general.getCboProjects,
            getForm: state.W75F2010.getForm,
            getCboPeriod: state.W75F2010.getCboPeriod,
            getCboAbsentType: state.W75F2010.getCboAbsentType
        }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            W75F2010Actions: bindActionCreators(W75F2010Actions, dispatch)
        })
    ),
    withStyles(styles)
)(W75F2010Popup);
